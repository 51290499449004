import React, {useState, useEffect, useContext} from 'react';
import HomeArts from './HomeArts';
import HomeSalud from './HomeSalud';
import HomeSports from './HomeSports';
import Loader from '../components/Loader';

import '../App.scss';
import {PageContext} from "../context/PageContext";
import fraseFooter from '../img/first-frase-footer-home.png'
import inicioVerVideoImg from '../img/inicio-ver-video.png'
import ModalVideo from "react-modal-video";

const Home = () => {

    const [videosLoaded, setVideosLoaded] = useState(0);
    const [isLoaded, setLoaded] = useState(false);
    const [activeCol, setActiveCol] = useState('salud');
    const [videoOpenned, setVideoOpenned] = useState(false);

    const {isDesktop} = useContext(PageContext)

    const handleVideoLoaded = () => {
        setVideosLoaded(videosLoaded + 1);
    }

    useEffect(() => {
        if (videosLoaded === 3)
            setLoaded(true);

    }, [videosLoaded])

    useEffect(() => {
        if (!isDesktop)
            setLoaded(true)
    }, [isDesktop])

    const handleClick = (item, e) => {
        e.preventDefault()
        e.stopPropagation()
        setActiveCol(item)
    }

    return <div className="container">
        <div className="wrapper">
            {
                !isLoaded && <Loader
                    className="spinner"
                    text="Cargando textos. Por favor, espere..."
                />
            }
            <div className="home" style={{opacity: !isLoaded ? 0 : 1}}>
                <div className="row">
                    <div className={`col ${activeCol === 'salud' && 'home-col-active'}`} onClick={e => handleClick('salud', e)}>
                        <HomeSalud onSetLoaded={handleVideoLoaded} />
                    </div>

                    <div className={`col ${activeCol === 'sports' && 'home-col-active'}`}  onClick={e => handleClick('sports', e)}>
                        <HomeSports onSetLoaded={handleVideoLoaded} />
                    </div>

                    <div className={`col ${activeCol === 'arts' && 'home-col-active'}`}  onClick={e => handleClick('arts', e)}>
                        <HomeArts onSetLoaded={handleVideoLoaded} />
                    </div>
                </div>
                <div className="home-title">
                    <img src={fraseFooter} alt=""/>
                </div>

                <div className="home-globo">
                    <img onClick={() => setVideoOpenned(true)} src={inicioVerVideoImg} alt="Ver vídeo"/>
                </div>

                <ModalVideo channel='youtube' autoplay isOpen={videoOpenned} videoId="uk4BfUxWreI" onClose={() => setVideoOpenned(false)} />
            </div>

        </div>


    </div>
}

export default Home;
