import { animated, useSpring } from "react-spring";
import * as d3 from "d3-ease";
import React, {useEffect} from "react";
import Mano from "../Mano";

const PolizaSliderItem = ({ style, item, currentStep, manoVisible, onManoSetLoaded, onManoChangeStep}) => {

    const h1Spring = useSpring({
        from: {
            opacity: 0,
            // transform: 'translate3d(0,-100%,0)'
        },
        to: {
            opacity: 1,
            // transform: 'translate3d(0%,0,0)'
        },
        // leave: { marginLeft: -50, transform: 'translate3d(-50%,0,0)' },
        config: {
            duration: 500,
        },
        delay: 200,
    });

    const h2Spring = useSpring({
        from: {
            opacity: 0,
            // transform: 'translate3d(0,-100%,0)',
        },
        to: {
            opacity: 1,
            // transform: 'translate3d(0%,0,0)'
        },
        // leave: { opacity: 0, transform: 'translate3d(-50%,0,0)' },
        config: {
            duration: 1000,
        },
        delay: 500,
    });

    const contentSpring = useSpring({
        from: {
            opacity: 0,
            transform: 'translate3d(0,-5%,0)',
        },
        to: {
            opacity: 1,
            transform: 'translate3d(0%,0,0)'
        },
        // leave: { opacity: 0, transform: 'translate3d(-50%,0,0)' },
        config: {
            duration: 1250,
            // mass: 500
            easing: d3.easePolyInOut
        },
        delay: 750,
    });

    const manoSpring = useSpring({
        from: {
            opacity: 0,
            transform: 'translate3d(0, 10%,0)',
        },
        to: {
            opacity: manoVisible ? 1 : 0,
            transform: 'translate3d(0%,0,0)'
        },
        // leave: { opacity: 0, transform: 'translate3d(-50%,0,0)' },
        config: {
            duration: 1500,
            // mass: 500
            easing: d3.easePolyInOut
        },
        delay: 100
    })

    // item.content += '<a href="#" className="enlCuadroMedico">ver cuadro médico</a>';

    return <animated.div style={{ ...style }} className="sliderItem">
        {
            item && <>
                <animated.h1 style={h1Spring}>{item.title}</animated.h1>
                <hr className="first-separator" />
                <animated.h2 style={h2Spring} dangerouslySetInnerHTML={{ __html: item.subtitle }} />
                <animated.div className="content" style={contentSpring} dangerouslySetInnerHTML={{ __html: item.content }} />

                <animated.div style={manoSpring}>
                    <Mano
                        className="mano mano-bottom"
                        onSetLoaded={onManoSetLoaded}
                        onChangeStep={onManoChangeStep}
                    />
                </animated.div>
            </>
        }
    </animated.div>;
}

export default PolizaSliderItem;
