import React, { useEffect } from 'react';
import { animated, useSpring } from "react-spring";
import letrasSrc from '../../img/salud/100/1-letras-doradas.png';

const offSet = 100;

const interp = i => r => `translate3d(0, ${1.5 * Math.sin(r + (i * 2 * Math.PI) / 1.6)}px, 0)`;

const Letras = props => {

    const letrasContainerInitialProps = {
        top: offSet * 2,
        left: 113,
        opacity: 0
    }

    const letrasInitialProps = {
        to: async (next, cancel,) => {
            while (true) {
                await next({ radians: 2 * Math.PI })
            }
        },
        from: { radians: 0 },
        config: { duration: 2500 },
        reset: true,
    };

    const [letrasContainer, setLetrasContainer] = useSpring(() => letrasContainerInitialProps)
    const [letras, setLetras, stopLetras] = useSpring(() => letrasInitialProps);

    useEffect(() => {
        if (!props.entrando) return;

        setLetrasContainer({
            top: -67,
            opacity: 1,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.entrando])

    useEffect(() => {
        if (!props.saliendo) return;

        setLetrasContainer(
            props.scroll === 'down' ?
                {
                    top: -offSet,
                    opacity: 0
                } : letrasContainerInitialProps
        );

        stopLetras()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.saliendo])


    return <animated.div
        style={letrasContainer}
        className="se">
        <animated.div
            style={{ transform: letras.radians.interpolate(interp(2)) }}
        >
            <img src={letrasSrc} alt="Letras" />
        </animated.div>
    </animated.div>
};

export default Letras;
