import React, { useEffect } from 'react';
import { animated, useSpring } from "react-spring";
import globoCSrc from "../../img/salud/100/2-globo-abajo.png";

const offSet = 100;

const interpGloboC = i => r => `translate3d(0, ${10 * Math.sin(r + (i * 2 * Math.PI) / 1.6)}px, 0)`;

const GloboCentral = props => {

    const globoCContainerInitialProps = {
        top: offSet * 2,
        left: -95,
        opacity: 0
    }

    const globoCInitialProps = {
        to: async (next, cancel,) => {
            while (true) {
                await next({ radians: 2 * Math.PI })
            }
        },
        from: { radians: 0 },
        config: { duration: 6500 },
        reset: true,
    };

    const [globoCContainer, setGloboCContainer] = useSpring(() => globoCContainerInitialProps)
    const [globoC, setGloboC, stopGloboC] = useSpring(() => globoCInitialProps)

    useEffect(() => {
        if (!props.entrando) return;

        setGloboCContainer({
            top: 270,
            opacity: 1
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.entrando])

    useEffect(() => {
        if (!props.saliendo) return;

        setGloboCContainer(
            props.scroll === 'down' ?
                {
                    top: -offSet,
                    opacity: 0
                } : globoCContainerInitialProps
        );

        stopGloboC()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.saliendo])


    return <animated.div
        style={globoCContainer}
        className="se"

    >
        <animated.div
            style={{ transform: globoC.radians.interpolate(interpGloboC(2)) }}
        >
            <img src={globoCSrc} alt="Globo central" />
        </animated.div>
    </animated.div>
};

export default GloboCentral;
