import React, { useState } from 'react';
import ContactoStep1 from './ContactoStep1';
import ContactoStep2 from './ContactoStep2';
import ContactoStep3 from './ContactoStep3';

const Contacto = () => {

    const [step, setStep] = useState(1);
    const [contactData, setContactData] = useState();

    const handleNextStep = () => step < 3 && setStep(step + 1);

    const handlePrevStep = () => step > 1 && setStep(step - 1);

    if (step === 1)
        return <ContactoStep1
            onNextStep={handleNextStep}
        />

    if (step === 2)
        return <ContactoStep2
            setContactData={setContactData}
            onNextStep={handleNextStep}
        />

    if (step === 3)
        return <ContactoStep3
            contactData={contactData}
            onPrevStep={handlePrevStep}
            onNextStep={handleNextStep}
        />
};

export default Contacto;
