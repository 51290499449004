import React, { useEffect, useState } from 'react';
import useScroll from "../hooks/useScroll";
import { useSpring, animated, useTransition } from "react-spring";
import PolizaSliderItem from "../components/Poliza/PolizaSliderItem";
import Dots from "../components/Dots";
import Loader from '../components/Loader';
import Error from '../components/Error';
import { useParams } from 'react-router-dom';

/**
 * Imágenes
 */
import Nube from '../components/Nube';

import Escenario1 from './Salud100Escenario1';
import Escenario2 from './Salud100Escenario2';
import Escenario3 from './Salud100Escenario3';
import Escenario4 from './Salud100Escenario4';
import Escenario5 from './Salud100Escenario5';
import SubmenuPrestaciones from "../components/SubmenuPrestaciones/salud";

const getData = (IdContenido) => {
    let url = '';
    switch (IdContenido) {
        case 'medicina-preventiva':
            url = 'https://acunsafirst.es/json/first_salud_medicina100.json';
            break;
        case 'reembolso-gastos':
            url = 'https://acunsafirst.es/json/first_salud_reembolso100.json';
            break;
        case 'garantias-exclusivas':
            url = 'https://acunsafirst.es/json/first_salud_garantia100.json';
            break;
        case 'cuadro-medico-nacional':
            url = 'https://acunsafirst.es/json/first_salud_cuadro100.json';
            break;
        default:
            url = 'https://acunsafirst.es/json/first_salud_clinica100.json';

    }
    return fetch(url).then(function (response) {
        return response.json();
    });
}

const SaludClinica100 = props => {
    const rightSpringInitialProps = { opacity: 1 };
    const [rightSpringProps, setRightSpringProps] = useSpring(() => rightSpringInitialProps);

    const { IdContenido } = useParams();

    const scrollDirection = useScroll()

    const [offSet, setOffSet] = useState(window.innerHeight / 2);

    const [currentSlide, setCurrentSlide] = useState(0);
    const [currentStep, setCurrentStep] = useState(0);
    const [loading, setLoading] = useState(true);
    const [slides, setSlides] = useState(null);
    const [error, setError] = useState(false);
    const [isLoaded, setLoaded] = useState(false);

    const [enterE1, setEnterE1] = useState(false);
    const [leaveE1, setLeaveE1] = useState(true);

    const [enterE2, setEnterE2] = useState(false);
    const [leaveE2, setLeaveE2] = useState(true);

    const [enterE3, setEnterE3] = useState(false);
    const [leaveE3, setLeaveE3] = useState(true);

    const [enterE4, setEnterE4] = useState(false);
    const [leaveE4, setLeaveE4] = useState(true);

    const [enterE5, setEnterE5] = useState(false);
    const [leaveE5, setLeaveE5] = useState(true);

    useEffect(() => {
        setCurrentSlide(0)
        setCurrentStep(0);
        setLoading(true);
        getSlideData();
        animations[0]();
    }, [IdContenido]);

    const getSlideData = () => {
        return getData(IdContenido)
            .then(d => {
                setSlides(d.slides)
            })
            .catch(e => {
                console.log('Ha habido un error en la petición o en los textos. En caso de ser los textos, por favor revíselos.')
                setError(true)
            })
            .finally(() => {
                setLoading(false)
                setLoaded(true)
            })
    }

    const handleOffSet = e => {
        setOffSet(e.target.innerHeight / 2)
    }

    useEffect(() => {
        window.addEventListener('resize', handleOffSet)

        return () => window.removeEventListener('resize', handleOffSet);

    }, []);

    useEffect(() => {

        if (isLoaded === false && scrollDirection === false)
            animations[0]();

        if (scrollDirection === false) return;
        let nextStep = currentStep + (scrollDirection === 'down' ? 1 : -1);

        if (nextStep < 0) nextStep = 0;

        if (nextStep >= slides.length) return;

        if (nextStep === currentStep) return;

        if (nextStep < animations.length)
            animations[nextStep]();
        else if (nextStep < slides.length)
            muestraSlide(nextStep)


        setCurrentStep(nextStep);

    }, [scrollDirection])

    const handleChange = index => {
        if (index < animations.length && index >= 0) {
            animations[index]();
            setCurrentStep(index);
            muestraSlide(index);
        } else if (index < slides.length) {
            animations[animations.length - 1]();
            muestraSlide(index)
            setCurrentStep(index);
        }

    }

    const muestraSlide = index => {
        setCurrentSlide(index);
    }

    const handleChangeStep = () => {
        let nextStep = currentStep + 1;

        if (nextStep < animations.length && nextStep >= 0) {
            animations[nextStep]();
            setCurrentStep(nextStep);
        } else if (nextStep < slides.length) {
            muestraSlide(nextStep)
            setCurrentStep(nextStep);
        }

    }

    const sliderTransition = useTransition(currentSlide, p => p, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: {
            duration: 1000
        }
    })

    const showE1 = () => {
        setEnterE1(true);
        setLeaveE1(false);

        setEnterE2(false);
        setLeaveE2(true);
        setEnterE3(false);
        setLeaveE3(true);
        setEnterE4(false);
        setLeaveE4(true);
        setEnterE5(false);
        setLeaveE5(true);

        setNubeContainer({
            opacity: 1,
            delay: 500,
        })
    }

    const showE2 = () => {
        setNubeContainer({
            opacity: 0
        })

        setEnterE1(false);
        setLeaveE1(true);

        setEnterE2(true);
        setLeaveE2(false);

        setEnterE3(false);
        setLeaveE3(true);
        setEnterE4(false);
        setLeaveE4(true);
        setEnterE5(false);
        setLeaveE5(true);

        setNubeContainer({
            opacity: 1,
            delay: 500,
        })
    }

    const showE3 = () => {
        setNubeContainer({
            opacity: 0
        })

        setEnterE1(false);
        setLeaveE1(true);
        setEnterE2(false);
        setLeaveE2(true);

        setEnterE3(true);
        setLeaveE3(false);

        setEnterE4(false);
        setLeaveE4(true);
        setEnterE5(false);
        setLeaveE5(true);

        setNubeContainer({
            opacity: 1,
            delay: 500,
        })
    }

    const showE4 = () => {

        setNubeContainer({
            opacity: 0
        })

        setEnterE1(false);
        setLeaveE1(true);
        setEnterE2(false);
        setLeaveE2(true);
        setEnterE3(false);
        setLeaveE3(true);

        setEnterE4(true);
        setLeaveE4(false);

        setEnterE5(false);
        setLeaveE5(true);

        setNubeContainer({
            opacity: 1,
            delay: 500,
        })
    }

    const showE5 = () => {
        setNubeContainer({
            opacity: 0
        })

        setEnterE1(false);
        setLeaveE1(true);
        setEnterE2(false);
        setLeaveE2(true);
        setEnterE3(false);
        setLeaveE3(true);
        setEnterE4(false);
        setLeaveE4(true);

        setEnterE5(true);
        setLeaveE5(false);

        setNubeContainer({
            opacity: 1,
            delay: 500,
        })
    }

    const animations = [
        () => {
            switch (IdContenido) {
                case 'medicina-preventiva':
                    showE2();
                    break;
                case 'reembolso-gastos':
                    showE3();
                    break;
                case 'garantias-exclusivas':
                    showE4();
                    break;
                case 'cuadro-medico-nacional':
                    showE5();
                    break;
                default:
                    showE1();
            }
            muestraSlide(0);
        },

        () => {
            switch (IdContenido) {
                case 'medicina-preventiva':
                    showE3();
                    break;
                case 'reembolso-gastos':
                    showE4();
                    break;
                case 'garantias-exclusivas':
                    showE5();
                    break;
                case 'cuadro-medico-nacional':
                    showE1();
                    break;
                default:
                    showE2();
            }
            muestraSlide(1);
        },

        () => {
            switch (IdContenido) {
                case 'medicina-preventiva':
                    showE4();
                    break;
                case 'reembolso-gastos':
                    showE5();
                    break;
                case 'garantias-exclusivas':
                    showE1();
                    break;
                case 'cuadro-medico-nacional':
                    showE2();
                    break;
                default:
                    showE3();
            }
            muestraSlide(2);
        },

        () => {
            switch (IdContenido) {
                case 'medicina-preventiva':
                    showE5();
                    break;
                case 'reembolso-gastos':
                    showE1();
                    break;
                case 'garantias-exclusivas':
                    showE2();
                    break;
                case 'cuadro-medico-nacional':
                    showE3();
                    break;
                default:
                    showE4();
            }
            muestraSlide(3);
        },

        () => {
            switch (IdContenido) {
                case 'medicina-preventiva':
                    showE1();
                    break;
                case 'reembolso-gastos':
                    showE2();
                    break;
                case 'garantias-exclusivas':
                    showE3();
                    break;
                case 'cuadro-medico-nacional':
                    showE4();
                    break;
                default:
                    showE5();
            }
            muestraSlide(4);
        }
    ];

    const nubeContainerInitialProps = {
        from: {
            opacity: 0,
            top: '50%'
        },
        to: {
            opacity: 1,
            top: offSet - 227
        },

    }
    const [nubeContainer, setNubeContainer] = useSpring(() => nubeContainerInitialProps)

    return (
        <div className={`container container-salud onehundred-salud onehundred-${IdContenido}`}>

            <SubmenuPrestaciones />

            <Loader
                className={isLoaded ? 'hide' : 'spinner'}
            />

            <div
                className="capas"
                style={isLoaded ? {} : { display: 'none' }}
            >
                {
                    error ? <Error text={'<p>Ha ocurrido un error inesperado. Por favor, vuelva a cargar la página dentro de unos minutos. </p><p>Si ve que el problema persiste, póngase en contacto con el servicio técnico.</p>'} /> :
                        (
                            <>
                                <animated.div className="left" >
                                    <animated.div
                                        className="se"
                                        style={nubeContainer}
                                    >
                                        <Nube style={{ width: 512, height: 450, borderSizing: 'border-box' }} />
                                    </animated.div>

                                    <Escenario1 enter={enterE1} leave={leaveE1} />
                                    <Escenario2 enter={enterE2} leave={leaveE2} scrollDirection={scrollDirection} />
                                    <Escenario3 enter={enterE3} leave={leaveE3} scrollDirection={scrollDirection} />
                                    <Escenario4 enter={enterE4} leave={leaveE4} scrollDirection={scrollDirection} />
                                    <Escenario5 enter={enterE5} leave={leaveE5} scrollDirection={scrollDirection} />

                                </animated.div>

                                <animated.div className="right right-hundred" style={{ ...rightSpringProps }}>
                                    {loading && !slides ? (
                                        <Loader
                                            text="Cargando textos. Por favor, espere..."
                                        />
                                    ) : (
                                            <div>
                                                {sliderTransition.map(({ item, props, key }) => {
                                                    return <PolizaSliderItem
                                                        key={key}
                                                        currentStep={currentStep}
                                                        item={slides[item]}
                                                        style={props}
                                                        manoVisible={slides !== null && currentStep < (slides.length - 1)}
                                                        onManoSetLoaded={() => setLoaded(true)}
                                                        onManoChangeStep={handleChangeStep}
                                                    />
                                                })}
                                                <Dots align={"right"} items={slides} currentStep={currentStep} onChange={e => handleChange(e)} />
                                                {/*{slides !== null && currentStep < (slides.length - 1) &&*/}

                                                {/*}*/}
                                            </div>
                                        )}
                                </animated.div>
                            </>
                        )
                }
            </div>
        </div >
    );
}

export default SaludClinica100;
