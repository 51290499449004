import React, { useEffect, useState } from 'react';
import { animated, useSpring, useTransition } from "react-spring";
import PolizaSliderItem from "../components/Poliza/PolizaSliderItem";
import Loader from '../components/Loader';
import useScroll from "../hooks/useScroll";
import Mano from '../components/Mano';
import SubmenuPrestaciones from "../components/SubmenuPrestaciones/arts";
import Error from '../components/Error';

// todo mock. Ver donde vamos a poner los datos para que mamen los gestione.
const getData = () => {
    return fetch('https://acunsafirst.es/json/first_arts.json').then(function (response) {
        return response.json();
    });
}

const Arts = () => {

    const [currentSlide, setCurrentSlide] = useState(0);
    const [slides, setSlides] = useState(null);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [useParallax, setUseParallax] = useState(true);
    const [isLoaded, setLoaded] = useState(false);

    useEffect(() => {
        getSlideData();
    }, []);

    const getSlideData = () => {
        getData()
            .then(d => {
                setSlides(d.slides)
                setLoading(false)
            })
            .catch(e => {
                setLoaded(true)
                console.log('Ha habido un error en la petición o en los textos. En caso de ser los textos, por favor revíselos.')
                setError(true)
            })
    }

    const muestraSlide = index => {
        setCurrentSlide(index);
    }

    /**
     * Parallax
     */
    const initialParallaxProps = { xy: [0, 0], config: { mass: 10, tension: 550, friction: 140 } };
    const [bailarinParallaxProps, setBailarinParallaxProps] = useSpring(() => initialParallaxProps)
    const transBailarin = (x, y) => `translate3d(${330 + x}px,${156 + y}px,0)`
    const [toreroParallaxProps, setToreroParallaxProps] = useSpring(() => initialParallaxProps)
    const transTorero = (x, y) => `translate3d(${306 + x}px,${44 + y}px,0)`;
    const [bailarinaParallaxProps, setBailarinaParallaxProps] = useSpring(() => initialParallaxProps);
    const transBailarina = (x, y) => `translate3d(${367 + x}px,${210 + y}px,0)`;

    const handleReset = () => {
        setBailarinaParallaxProps(initialParallaxProps);
        setBailarinParallaxProps(initialParallaxProps);
        setToreroParallaxProps(initialParallaxProps);
    }

    const calc = (width, x, height, y, ratio) => {
        const margen = Math.round((window.innerWidth - width) / 2);
        const mousePostX = x - margen + 3;
        const mousePostY = y + 2;
        return [calcWidth(width, mousePostX, ratio), calcHeight(height, mousePostY, ratio)]
    }

    const calcWidth = (width, position, ratio) => {
        if (width > width / 2)
            width = width / 2;
        const actualPos = position - width;
        const actualPosPercent = actualPos * 100 / width;
        const x = actualPosPercent * ratio / 100;
        return x;
    }

    const calcHeight = (height, position, ratio) => {
        if (height > height / 2)
            height = height / 2;
        const actualPos = position - height;
        const actualPosPercent = actualPos * 100 / height;
        const y = actualPosPercent * ratio / 100;
        return y;
    }

    const handleChange = (width, height, x, y) => {
        setBailarinParallaxProps({ xy: calc(width, x, height, y, 25) })
        setToreroParallaxProps({ xy: calc(width, x, height, y, 12.5) })
        setBailarinaParallaxProps({ xy: calc(width, x, height, y, 5) })
    }

    /**
     * Pasos
     */
    const [currentStep, setCurrentStep] = useState(0);
    const scrollDirection = useScroll();

    const sliderTransition = useTransition(currentSlide, p => p, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: {
            duration: 1000
        }
    })

    /**
     * Spring
     */
    const rightSpringInitialProps = { left: '100%', opacity: 0 };
    const leftSpringInitialProps = { left: 0, a: 1 };

    const [leftSpringProps, setLeftSpringProps] = useSpring(() => leftSpringInitialProps)
    const [rightSpringProps, setRightSpringProps] = useSpring(() => rightSpringInitialProps);

    const handleChangeStep = () => {
        let nextStep = currentStep + 1;

        if (nextStep < animations.length && nextStep >= 0) {
            animations[nextStep]();
            setCurrentStep(nextStep);
        }

    }

    useEffect(() => {

        if (scrollDirection === false) return;
        let nextStep = currentStep + (scrollDirection === 'down' ? 1 : -1);

        if (nextStep < 0)
            nextStep = 0;
        if (nextStep >= animations.length) return;

        if (nextStep < animations.length && nextStep >= 0) {
            animations[nextStep]();
        }

        setCurrentStep(nextStep);

    }, [scrollDirection]);

    const animations = [
        () => {
            setLeftSpringProps(leftSpringInitialProps);
            setRightSpringProps(rightSpringInitialProps);
            setUseParallax(true);
            handleReset();
        },

        () => {
            setUseParallax(false);

            setLeftSpringProps({
                left: -512,
            })

            setBailarinParallaxProps({ xy: [94, 0] });
            setBailarinaParallaxProps({ xy: [41, 0] });
            setToreroParallaxProps({ xy: [64, 0] });

            setTimeout(() => {

                setRightSpringProps({
                    left: '50%',
                    opacity: 1
                })

            }, 100)
            muestraSlide(0);

        }
    ];

    const images = [

        {
            src: require("../img/arts/torero.png"),
            style: { transform: toreroParallaxProps.xy.interpolate(transTorero) },
            alt: 'torero'
        },
        {
            src: require("../img/arts/bailarina.png"),
            style: { transform: bailarinaParallaxProps.xy.interpolate(transBailarina) },
            alt: 'bailarina',
        },
        {
            src: require("../img/arts/bailarin.png"),
            style: { transform: bailarinParallaxProps.xy.interpolate(transBailarin) },
            alt: 'bailarin'
        },
    ]

    return (
        <>
            <SubmenuPrestaciones />
            <div className="container container-arts">
                <Loader
                    className={isLoaded ? 'hide' : 'spinner'}
                />

                <div
                    className="capas"
                    style={isLoaded ? {} : { display: 'none' }}
                >
                    {
                        error ? <Error text={'<p>Ha ocurrido un error inesperado. Por favor, vuelva a cargar la página dentro de unos minutos. </p><p>Si ve que el problema persiste, póngase en contacto con el servicio técnico.</p>'} /> :
                            (
                                <div>
                                    <animated.div
                                        className="left"
                                        style={{ ...leftSpringProps, width: '1024px', height: '550px' }}
                                        onMouseMove={({ clientX: x, clientY: y }) => useParallax && handleChange(1024, 550, x, y)}
                                        onMouseLeave={() => useParallax && handleReset()}
                                    >

                                        <div>
                                            <video style={{ width: '100%', height: '100%', position: 'absolute' }} muted="muted" autoPlay="autoplay" loop="loop" id="video_background" preload="auto" volume="50" muted playsInline disablePictureInPicture controlsList="nodownload">
                                                <source src="./arts.mp4" type="video/mp4" />
                                            </video>
                                        </div>

                                        {images.length > 0 && images.map((image, i) => {

                                            return (
                                                <animated.div key={i} className="se" style={image.style}>
                                                    <img src={image.src} alt={image.alt} onMouseEnter={image.onMouseEnter !== undefined ? image.onMouseEnter : undefined} onMouseLeave={image.onMouseLeave !== undefined ? image.onMouseLeave : undefined} onLoad={image.onLoad !== undefined ? image.onLoad : undefined} />
                                                </animated.div>
                                            )
                                        })}
                                    </animated.div>

                                    <animated.div className="right" style={{ ...rightSpringProps, height: '550px', top: 30 }}>
                                        {loading ? (
                                            <Loader
                                                text="Cargando textos. Por favor, espere..."
                                            />
                                        ) : (
                                                <>
                                                    {sliderTransition.map(({ item, props, key }) => {
                                                        return <PolizaSliderItem
                                                            key={key}
                                                            currentStep={currentStep}
                                                            item={slides[item]}
                                                            style={props}
                                                        />
                                                    })}
                                                    {/*<Dots items={slides} currentStep={currentStep - 1} />*/}
                                                </>
                                            )}
                                    </animated.div>
                                    {slides !== null && currentStep < slides.length &&
                                        <Mano
                                            className="mano mano-right"
                                            onSetLoaded={() => setLoaded(true)}
                                            onChangeStep={handleChangeStep}
                                        />
                                    }
                                </div>
                            )
                    }
                </div>

            </div>
        </>
    );
}

Arts.propTypes = {

}

export default Arts;


