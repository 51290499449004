import React from 'react';
import Canvas from '../Canvas/'
import Cloud from "../Canvas/Environment/Cloud";

const Nube = props => {

    return (
        <div className={props.className}>
            <Canvas style={props.style}>
                <Cloud size={[7, 5]} />
            </Canvas>
        </div>
    )
}

export default Nube;