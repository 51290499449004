import React from 'react';

import BailarinPelota from "../components/Elementos/BailarinPelota";
import Columna from "../components/Elementos/Columna";
import GimnastaPiano from "../components/Elementos/GimnastaPiano";
import GimnastaLazo from "../components/Elementos/GimnastaLazo";

const Escenario2 = ({ enter, leave, scrollDirection }) => {
    return (
        <div className="escenario escenario2" >
            <Columna entrando={enter} scroll={scrollDirection} saliendo={leave} />
            <BailarinPelota entrando={enter} scroll={scrollDirection} saliendo={leave} />
            <GimnastaPiano entrando={enter} scroll={scrollDirection} saliendo={leave} />
            <GimnastaLazo entrando={enter} scroll={scrollDirection} saliendo={leave} />

        </div>
    )
}

export default Escenario2;