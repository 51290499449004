import React, { useEffect } from 'react';
import { animated, useSpring } from "react-spring";
import carruselSrc from '../../img/salud/100/4-carrusel.png';

const offSet = 100;

const interp = i => r => `translate3d(0, ${5 * Math.sin(r + (i * 2 * Math.PI) / 1.6)}px, 0)`;

const Carrusel = props => {

    const carruselContainerInitialProps = {
        top: offSet * 2,
        left: -80,
        opacity: 0
    }

    const carruselInitialProps = {
        to: async (next, cancel,) => {
            while (true) {
                await next({ radians: 2 * Math.PI })
            }
        },
        from: { radians: 0 },
        config: { duration: 4000 },
        reset: true,
    };

    const [carruselContainer, setCarruselContainer] = useSpring(() => carruselContainerInitialProps)
    const [carrusel, setCarrusel, stopCarrusel] = useSpring(() => carruselInitialProps);

    useEffect(() => {
        if (!props.entrando) return;

        setCarruselContainer({
            top: 345,
            opacity: 1
        })
    }, [props.entrando])

    useEffect(() => {
        if (!props.saliendo) return;

        setCarruselContainer(
            props.scroll === 'down' ?
                {
                    top: -offSet,
                    opacity: 0
                } : carruselContainerInitialProps
        );

        stopCarrusel()

    }, [props.saliendo])


    return <animated.div
        style={carruselContainer}
        className="se">
        <animated.div
            style={{ transform: carrusel.radians.interpolate(interp(2)) }}
        >
            <img src={carruselSrc} alt="Carrusel" />
        </animated.div>
    </animated.div>
};

export default Carrusel;
