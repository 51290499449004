import {useState, useEffect} from 'react'
import {Texture} from 'three'

import {loadTexture} from '../helpers/loadingManager';
// import {loadTexture} from '~js/helpers/loadingManager'

export default function useTexture(src) {
  const [texture, setTexture] = useState(new Texture())

  useEffect(()=> {
    loadTexture(src, setTexture)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return texture
}
