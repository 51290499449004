import React, { useEffect } from 'react';
import { animated, useSpring } from "react-spring";
import nubePequeSrc from '../../img/nube-peque.png';

const offSet = 100;

const interpNube = i => r => `translate3d(0, ${2 * Math.sin(r + (i * 2 * Math.PI) / 1.6)}px, 0)`;

const NubePeque = props => {

    const nubePequeContainerInitialProps = {
        top: offSet * 2,
        left: -7,
        opacity: 0
    }

    const nubePequeInitialProps = {
        to: async (next, cancel,) => {
            while (true) {
                await next({ radians: 2 * Math.PI })
            }
        },
        from: { radians: 0 },
        config: { duration: 4000 },
        reset: true,
    };

    const [nubePequeContainer, setNubePequeContainer] = useSpring(() => nubePequeContainerInitialProps)
    const [nubePeque, setNubePeque, stopNubePeque] = useSpring(() => nubePequeInitialProps);

    useEffect(() => {
        if (!props.entrando) return;

        setNubePequeContainer({
            top: 300,
            left: 63,
            opacity: 1,
        })
    }, [props.entrando])

    useEffect(() => {
        if (!props.saliendo) return;

        setNubePequeContainer(
            props.scroll === 'down' ?
                {
                    top: -offSet,
                    opacity: 0
                } : nubePequeContainerInitialProps
        );

        stopNubePeque()

    }, [props.saliendo])


    return <animated.div
        style={nubePequeContainer}
        className="se"
    >
        <animated.div
            style={{ transform: nubePeque.radians.interpolate(interpNube(2)) }}
        >
            <img src={nubePequeSrc} alt="Nube" />
        </animated.div>
    </animated.div>
};

export default NubePeque;
