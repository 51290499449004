import React from 'react';

import Hipica from "../components/Elementos/Hipica";
import Ajedrez from "../components/Elementos/Ajedrez";

const Escenario3 = ({ enter, leave, scrollDirection }) => {
    return (
        <div className="escenario escenario3">
            <Hipica entrando={enter} scroll={scrollDirection} saliendo={leave} />
            <Ajedrez entrando={enter} scroll={scrollDirection} saliendo={leave} />
        </div>
    )
}

export default Escenario3;