import React from 'react';

import Bailarin from "../components/Elementos/Bailarin";
import Bailarina from "../components/Elementos/Bailarina";
import Faberge from "../components/Elementos/Faberge";

const Escenario1 = ({ enter, leave }) => {
    return (
        <div className="escenario escenario1">
            <Bailarin entrando={enter} saliendo={leave} />
            <Faberge entrando={enter} offSet saliendo={leave} />
            <Bailarina entrando={enter} saliendo={leave} />

        </div>
    )
}

export default Escenario1;