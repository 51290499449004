import React from 'react';
import { nonUndefined } from '../../common/util';

import s from './contacto.module.scss';

import graciasImg from './sello-gracias-mensaje.png'

const ContactoStep3 = ({ onPrevStep, contactData }) => <div className={`container ${s.paso3}`}>
    <div className={s.contactRegards}>
        <p>Nombre {contactData && nonUndefined(contactData.nombre)} <br/>
        Apellidos {contactData && nonUndefined(contactData.apellidos)}</p>
        <img className={s.img} src={graciasImg} alt="Gracias por su solicitud de información"/>
    </div>
</div>

export default ContactoStep3;
