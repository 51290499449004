import React, { useEffect, useState } from 'react';
import { animated, useSpring, useTransition } from "react-spring";
import PolizaSliderItem from "../components/Poliza/PolizaSliderItem";
import Loader from '../components/Loader';
import useScroll from "../hooks/useScroll";
import Mano from '../components/Mano';
import SubmenuPrestaciones from "../components/SubmenuPrestaciones/sports";
import Error from '../components/Error';

// todo mock. Ver donde vamos a poner los datos para que mamen los gestione.
const getData = () => {
    return fetch('https://acunsafirst.es/json/first_sports.json').then(function (response) {
        return response.json();
    });
}

const Sports = () => {

    //TODO: ¿Por qué no se está cambiando current Slide?
    //TODO: (Alfredo) Porque está haciendo una petición a acunsafirst.orquestamedia con los textos.
    //Entiendo que tu pregunta viene porque estabas modificando los textos de los json de data y no lo verías plasmado.
    const [currentSlide, setCurrentSlide] = useState(0);
    const [slides, setSlides] = useState(null);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [useParallax, setUseParallax] = useState(true);
    const [isLoaded, setLoaded] = useState(false);

    useEffect(() => {
        getSlideData();
    }, []);

    const getSlideData = () => {
        getData()
            .then(d => {
                setSlides(d.slides)
                setLoading(false)
            })
            .catch(e => {
                setLoaded(true)
                console.log('Ha habido un error en la petición o en los textos. En caso de ser los textos, por favor revíselos.')
                setError(true)
            })
    }

    const muestraSlide = index => {
        setCurrentSlide(index);
    }

    /**
     * Parallax
     */
    const initialParallaxProps = { xy: [0, 0], config: { mass: 10, tension: 550, friction: 140 } };
    const [futbolParallaxProps, setFutbolProps] = useSpring(() => initialParallaxProps)
    const transFutbol = (x, y) => `translate3d(${375 + x}px,${40 + y}px,0)`
    const [corredoraParallaxProps, setCorredoraParallaxProps] = useSpring(() => initialParallaxProps)
    const transCorredora = (x, y) => `translate3d(${495 + x}px,${200 + y}px,0)`;
    const [baloncestoParallaxProps, setBaloncestoParallaxProps] = useSpring(() => initialParallaxProps);
    const transBaloncesto = (x, y) => `translate3d(${145 + x}px,${125 + y}px,0)`;

    const handleReset = () => {
        setBaloncestoParallaxProps(initialParallaxProps);
        setFutbolProps(initialParallaxProps);
        setCorredoraParallaxProps(initialParallaxProps);
    }

    const calc = (width, x, height, y, ratio) => {
        const margen = Math.round((window.innerWidth - width) / 2);
        const mousePostX = x - margen + 3;
        const mousePostY = y + 2;
        return [calcWidth(width, mousePostX, ratio), calcHeight(height, mousePostY, ratio)]
    }

    const calcWidth = (width, position, ratio) => {
        if (width > width / 2)
            width = width / 2;
        const actualPos = position - width;
        const actualPosPercent = actualPos * 100 / width;
        const x = actualPosPercent * ratio / 100;
        return x;
    }

    const calcHeight = (height, position, ratio) => {
        if (height > height / 2)
            height = height / 2;
        const actualPos = position - height;
        const actualPosPercent = actualPos * 100 / height;
        const y = actualPosPercent * ratio / 100;
        return y;
    }

    const handleChange = (width, height, x, y) => {
        setFutbolProps({ xy: calc(width, x, height, y, 25) })
        setCorredoraParallaxProps({ xy: calc(width, x, height, y, 12.5) })
        setBaloncestoParallaxProps({ xy: calc(width, x, height, y, 5) })
    }

    /**
     * Pasos
     */
    const [currentStep, setCurrentStep] = useState(0);
    const scrollDirection = useScroll();

    const sliderTransition = useTransition(currentSlide, p => p, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: {
            duration: 1000
        }
    })

    /**
     * Spring
     */
    const rightSpringInitialProps = { left: '100%', opacity: 0 };
    const leftSpringInitialProps = { left: 0, a: 1 };
    const tenistaSpringInitialProps = { left: 635, top: 55, opacity: 1 };
    const golfistaSpringInitialProps = { left: 360, top: 200, opacity: 1 };

    const [leftSpringProps, setLeftSpringProps] = useSpring(() => leftSpringInitialProps)
    const [rightSpringProps, setRightSpringProps] = useSpring(() => rightSpringInitialProps);
    const [tenistaSpringProps, setTenistaSpringProps] = useSpring(() => tenistaSpringInitialProps);
    const [golfistaSpringProps, setGolfistaSpringProps] = useSpring(() => golfistaSpringInitialProps);

    const handleChangeStep = () => {
        let nextStep = currentStep + 1;

        if (nextStep < animations.length && nextStep >= 0) {
            animations[nextStep]();
            setCurrentStep(nextStep);
        }

    }

    useEffect(() => {

        console.log('scroll');
        if (scrollDirection === false) return;
        let nextStep = currentStep + (scrollDirection === 'down' ? 1 : -1);

        if (nextStep < 0)
            nextStep = 0;
        if (nextStep >= animations.length) return;

        if (nextStep < animations.length && nextStep >= 0) {
            animations[nextStep]();
        }

        setCurrentStep(nextStep);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scrollDirection]);

    const animations = [
        () => {
            setLeftSpringProps(leftSpringInitialProps);
            setRightSpringProps(rightSpringInitialProps);
            setTenistaSpringProps(tenistaSpringInitialProps);
            setGolfistaSpringProps(golfistaSpringInitialProps);
            setUseParallax(true);
            handleReset();
        },

        () => {
            setUseParallax(false);

            setLeftSpringProps({
                left: -450,
            })

            // setFutbolProps({ xy: [94, 0] });
            // setBaloncestoParallaxProps({ xy: [41, 0] });
            // setCorredoraParallaxProps({ xy: [64, 0] });
            // setTenistaSpringProps({
            //     left: 345 + offSet,
            //     duration: 250
            // })

            // setGolfistaSpringProps({
            //     opacity: 0,
            // })



            setTimeout(() => {

                setRightSpringProps({
                    left: '50%',
                    opacity: 1
                })

            }, 100)
            muestraSlide(0);

        },
    ];

    const images = [
        {
            src: require("../img/sports/baloncesto.png"),
            style: { transform: baloncestoParallaxProps.xy.interpolate(transBaloncesto) },
            alt: 'baloncesto',
        },
        {
            src: require("../img/sports/corredora.png"),
            style: { transform: corredoraParallaxProps.xy.interpolate(transCorredora) },
            alt: 'corredora'
        },
        {
            src: require("../img/sports/futbol.png"),
            style: { transform: futbolParallaxProps.xy.interpolate(transFutbol) },
            alt: 'futbol'
        },
        {
            src: require("../img/sports/golfista.png"),
            style: golfistaSpringProps,
            alt: 'golfista'
        },
        {
            src: require("../img/sports/tenista.png"),
            style: tenistaSpringProps,
            alt: 'tenista'
        },
    ]

    return (
        <>
            <SubmenuPrestaciones />
            <div className="container container-sports" >
                <Loader
                    className={isLoaded ? 'hide' : 'spinner'}
                    text="Otra forma de entender la vida, otra forma de asegurar la salud."
                />
                <div
                    className="capas"
                    style={isLoaded ? {} : { display: 'none' }}
                >
                    {
                        error ? <Error text={'<p>Ha ocurrido un error inesperado. Por favor, vuelva a cargar la página dentro de unos minutos. </p><p>Si ve que el problema persiste, póngase en contacto con el servicio técnico.</p>'} /> :
                            (
                                <div>
                                    <animated.div
                                        className="left"
                                        style={{ ...leftSpringProps, width: '1024px', height: '550px' }}
                                        onMouseMove={({ clientX: x, clientY: y }) => useParallax && handleChange(1024, 550, x, y)}
                                        onMouseLeave={() => useParallax && handleReset()}
                                    >

                                        <div>
                                            <video style={{ width: '100%', height: '100%', position: 'absolute' }} autoPlay="autoplay" loop="loop" id="video_background" preload="auto" volume="50" muted playsInline disablePictureInPicture controlsList="nodownload">
                                                <source src="./sports.mp4" type="video/mp4" />
                                            </video>
                                        </div>

                                        {images.length > 0 && images.map((image, i) => {

                                            return (
                                                <animated.div key={i} className="se" style={image.style}>
                                                    <img src={image.src} alt={image.alt} onLoad={image.onLoad !== undefined ? image.onLoad : undefined} />
                                                </animated.div>
                                            )
                                        })}
                                    </animated.div>

                                    <animated.div className="right" style={{ ...rightSpringProps, height: '550px', top: 30 }}>
                                        {loading ? (
                                            <Loader
                                                text="Cargando textos. Por favor, espere..."
                                            />
                                        ) : (
                                                <>
                                                    {sliderTransition.map(({ item, props, key }) => {
                                                        return <PolizaSliderItem
                                                            key={key}
                                                            currentStep={currentStep}
                                                            item={slides[item]}
                                                            style={props}
                                                        />
                                                    })}
                                                    {/*<Dots items={slides} currentStep={currentStep - 1} />*/}
                                                </>
                                            )}
                                    </animated.div>
                                    {slides !== null && currentStep < slides.length &&
                                        <Mano
                                            className="mano mano-right"
                                            onSetLoaded={() => setLoaded(true)}
                                            onChangeStep={handleChangeStep}
                                        />
                                    }
                                </div>
                            )
                    }

                </div>

            </div>
        </>
    );
}

export default Sports;


