import React, { useEffect } from 'react';
import { animated, useSpring } from "react-spring";
import globoDSrc from "../../img/salud/100/3-globoderecha.png";

const offSet = 100;

const interpGloboD = i => r => `translate3d(0, ${3 * Math.sin(r + (i * 2 * Math.PI) / 1.6)}px, 0)`;

const GloboCentral = props => {

    const globoDContainerInitialProps = {
        top: offSet * 2,
        left: 60,
        opacity: 0
    }

    const globoDInitialProps = {
        to: async (next, cancel,) => {
            while (true) {
                await next({ radians: 2 * Math.PI })
            }
        },
        from: { radians: 0 },
        config: { duration: 6500 },
        reset: true,
    };

    const [globoDContainer, setGloboDContainer] = useSpring(() => globoDContainerInitialProps)
    const [globoD, setGloboD, stopGloboD] = useSpring(() => globoDInitialProps)

    useEffect(() => {
        if (!props.entrando) return;

        setGloboDContainer({
            top: 190,
            opacity: 1
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.entrando])

    useEffect(() => {
        if (!props.saliendo) return;

        setGloboDContainer(
            props.scroll === 'down' ?
                {
                    top: -offSet,
                    opacity: 0
                } : globoDContainerInitialProps
        );

        stopGloboD()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.saliendo])


    return <animated.div
        style={globoDContainer}
        className="se"

    >
        <animated.div
            style={{ transform: globoD.radians.interpolate(interpGloboD(2)) }}
        >
            <img src={globoDSrc} alt="Globo derecha" />
        </animated.div>
    </animated.div>
};

export default GloboCentral;
