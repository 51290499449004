import React from 'react';

import Lampara from "../components/Elementos/Lampara";
import Cortina from "../components/Elementos/Cortina";
import DirectorOrquesta from "../components/Elementos/DirectorOrquesta";
import ViolinNotas from "../components/Elementos/ViolinNotas";
import BailarinaOpera from "../components/Elementos/BailarinaOpera";

const Escenario4 = ({ enter, leave, scrollDirection }) => {
    return (
        <div className="escenario escenario4">
            <Lampara entrando={enter} scroll={scrollDirection} saliendo={leave} />
            <DirectorOrquesta entrando={enter} scroll={scrollDirection} saliendo={leave} />
            <Cortina entrando={enter} scroll={scrollDirection} saliendo={leave} />
            <BailarinaOpera entrando={enter} scroll={scrollDirection} saliendo={leave} />
            <ViolinNotas entrando={enter} scroll={scrollDirection} saliendo={leave} />
        </div>
    )
}

export default Escenario4;