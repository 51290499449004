import React, { useEffect } from 'react';
import { animated, useSpring } from "react-spring";
import globoISrc from "../../img/salud/100/4-globo-izda.png";

const offSet = 100;

const interpGloboI = i => r => `translate3d(0, ${3 * Math.sin(r + (i * 2 * Math.PI) / 1.6)}px, 0)`;

const GloboIzquierda = props => {

    const globoIContainerInitialProps = {
        top: offSet * 2,
        left: -297,
        opacity: 0
    }

    const globoIInitialProps = {
        to: async (next, cancel,) => {
            while (true) {
                await next({ radians: 2 * Math.PI })
            }
        },
        from: { radians: 0 },
        config: { duration: 6500 },
        reset: true,
    };

    const [globoIContainer, setGloboIContainer] = useSpring(() => globoIContainerInitialProps)
    const [globoI, setGloboI, stopGloboI] = useSpring(() => globoIInitialProps)

    useEffect(() => {
        if (!props.entrando) return;

        setGloboIContainer({
            top: 165,
            opacity: 1
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.entrando])

    useEffect(() => {
        if (!props.saliendo) return;

        setGloboIContainer(
            props.scroll === 'down' ?
                {
                    top: -offSet,
                    opacity: 0
                } : globoIContainerInitialProps
        );

        stopGloboI()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.saliendo])


    return <animated.div
        style={globoIContainer}
        className="se"

    >
        <animated.div
            style={{ transform: globoI.radians.interpolate(interpGloboI(2)) }}
        >
            <img src={globoISrc} alt="Globo izquierda" />
        </animated.div>
    </animated.div>
};

export default GloboIzquierda;
