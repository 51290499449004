import React, { useEffect } from 'react';
import { animated, useSpring } from "react-spring";
import cocheSrc from '../../img/salud/100/2-coche-lazo.png';

const offSet = 100;

const interp = i => r => `translate3d(0, ${4 * Math.sin(r + (i * 2 * Math.PI) / 1.6)}px, 0)`;

const Coche = props => {

    const cocheContainerInitialProps = {
        top: offSet * 2,
        left: 5,
        opacity: 0
    }

    const cocheInitialProps = {
        to: async (next, cancel,) => {
            while (true) {
                await next({ radians: 2 * Math.PI })
            }
        },
        from: { radians: 0 },
        config: { duration: 4000 },
        reset: true,
    };

    const [cocheContainer, setCocheContainer] = useSpring(() => cocheContainerInitialProps)
    const [coche, setCoche, stopCoche] = useSpring(() => cocheInitialProps);

    useEffect(() => {
        if (!props.entrando) return;

        setCocheContainer({
            top: 187,
            opacity: 1
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.entrando])

    useEffect(() => {
        if (!props.saliendo) return;

        setCocheContainer(
            props.scroll === 'down' ?
                {
                    top: -offSet,
                    opacity: 0
                } : cocheContainerInitialProps
        );

        stopCoche()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.saliendo])


    return <animated.div
        style={cocheContainer}
        className="se"
    >
        <animated.div
            style={{ transform: coche.radians.interpolate(interp(2)) }}
        >
            <img src={cocheSrc} alt="Coche lazo" />
        </animated.div>
    </animated.div>
};

export default Coche;
