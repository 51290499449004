import React from 'react';

import Mundos from "../components/Elementos/Mundos";
import Faro from "../components/Elementos/Faro";
import Barco from "../components/Elementos/Barco";
import Timon from "../components/Elementos/Timon";
import NubePeque from '../components/Elementos/NubePeque';
import NubeGrande from '../components/Elementos/NubeGrande';

const Escenario2 = ({ enter, leave, scrollDirection }) => {
    return (
        <div className="escenario escenario2">
            <NubePeque entrando={enter} scroll={scrollDirection} saliendo={leave} />
            <NubeGrande entrando={enter} scroll={scrollDirection} saliendo={leave} />
            <Mundos entrando={enter} scroll={scrollDirection} saliendo={leave} />
            <Faro entrando={enter} scroll={scrollDirection} saliendo={leave} />
            <Barco entrando={enter} scroll={scrollDirection} saliendo={leave} />
            <Timon entrando={enter} scroll={scrollDirection} saliendo={leave} />
        </div>
    )
}

export default Escenario2;