import React, { useEffect, useState } from 'react';
import { animated, useSpring, useTransition } from "react-spring";
import PolizaSliderItem from "../components/Poliza/PolizaSliderItem";
import Loader from '../components/Loader';
import useScroll from "../hooks/useScroll";
import Mano from '../components/Mano';
import SubmenuPrestaciones from "../components/SubmenuPrestaciones/salud";

import Error from '../components/Error';

// todo mock. Ver donde vamos a poner los datos para que mamen los gestione.
const getData = () => {
    return fetch('https://acunsafirst.es/json/first_salud.json').then(function (response) {
        return response.json();
    });
}

const Salud = () => {

    const [currentSlide, setCurrentSlide] = useState(0);
    const [slides, setSlides] = useState(null);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [useParallax, setUseParallax] = useState(true);
    const [isLoaded, setLoaded] = useState(false);

    useEffect(() => {
        getSlideData();
    }, []);

    const getSlideData = () => {
        getData()
            .then(d => {
                setSlides(d.slides)
                setLoading(false)
            })
            .catch(e => {
                setLoaded(true)
                console.log('Ha habido un error en la petición o en los textos. En caso de ser los textos, por favor revíselos.')
                setError(true)
            })
    }

    const muestraSlide = index => {
        setCurrentSlide(index);
    }

    /**
     * Parallax
     */
    const initialParallaxProps = { xy: [0, 0], config: { mass: 10, tension: 550, friction: 140 } };
    const [globosIzda, setGlobosIzdaProps] = useSpring(() => initialParallaxProps)
    const transGlobos = (x, y) => `translate3d(${45 + x}px,${105 + y}px,0)`
    const [avionetaParallaxProps, setAvionetaParallaxProps] = useSpring(() => initialParallaxProps);
    const transAvioneta = (x, y) => `translate3d(${669 + x}px,${155 + y}px,0)`;
    const [cocheParallaxProps, setCocheParallaxProps] = useSpring(() => initialParallaxProps);
    const transCoche = (x, y) => `translate3d(${495 + x}px,${380 + y}px,0)`;
    const [fParallaxProps, setFParallaxProps] = useSpring(() => initialParallaxProps);
    const transF = (x, y) => `translate3d(${650 + x}px,${270 + y}px,0)`;
    const [barcoParallaxProps, setBarcoParallaxProps] = useSpring(() => initialParallaxProps);
    const transBarco = (x, y) => `translate3d(${-8 + x}px,${275 + y}px,0)`;
    const [globoDParallaxProps, setGloboDParallaxProps] = useSpring(() => initialParallaxProps);
    const transGloboD = (x, y) => `translate3d(${465 + x}px,${97 + y}px,0)`;
    const [zeppelinParallaxProps, setZeppelinParallaxProps] = useSpring(() => initialParallaxProps);
    const transZeppelin = (x, y) => `translate3d(${-34 + x}px,${-56 + y}px,0)`;
    const [maletasParallaxProps, setMaletasParallaxProps] = useSpring(() => initialParallaxProps);
    const transMaletas = (x, y) => `translate3d(${250 + x}px,${382 + y}px,0)`;

    // const [butacaParallaxProps, setButacaParallaxProps] = useSpring(() => initialParallaxProps)
    // const transButaca = (x, y) => `translate3d(${240 + x}px,${190 + y}px,0)`;

    const handleReset = () => {
        setAvionetaParallaxProps(initialParallaxProps);
        setGlobosIzdaProps(initialParallaxProps);
        setCocheParallaxProps(initialParallaxProps)
        setFParallaxProps(initialParallaxProps)
        setBarcoParallaxProps(initialParallaxProps)
        setGloboDParallaxProps(initialParallaxProps)
        setZeppelinParallaxProps(initialParallaxProps)
        setMaletasParallaxProps(initialParallaxProps)

        // setButacaParallaxProps(initialParallaxProps);
    }

    const calc = (width, x, height, y, ratio) => {
        const margen = Math.round((window.innerWidth - width) / 2);
        const mousePostX = x - margen + 3;
        const mousePostY = y + 2;
        return [calcWidth(width, mousePostX, ratio), calcHeight(height, mousePostY, ratio)]
    }

    const calcWidth = (width, position, ratio) => {
        if (width > width / 2)
            width = width / 2;
        const actualPos = position - width;
        const actualPosPercent = actualPos * 100 / width;
        const x = actualPosPercent * ratio / 100;
        return x;
    }

    const calcHeight = (height, position, ratio) => {
        if (height > height / 2)
            height = height / 2;
        const actualPos = position - height;
        const actualPosPercent = actualPos * 100 / height;
        const y = actualPosPercent * ratio / 100;
        return y;
    }

    const handleChange = (width, height, x, y) => {
        setGlobosIzdaProps({ xy: calc(width, x, height, y, 25) })
        setAvionetaParallaxProps({ xy: calc(width, x, height, y, 5) })
        setCocheParallaxProps({ xy: calc(width, x, height, y, 5) })
        setFParallaxProps({ xy: calc(width, x, height, y, 7) })
        setBarcoParallaxProps({ xy: calc(width, x, height, y, 7) })
        setGloboDParallaxProps({ xy: calc(width, x, height, y, 25) })
        setZeppelinParallaxProps({ xy: calc(width, x, height, y, 10) })
        setMaletasParallaxProps({ xy: calc(width, x, height, y, 5) })
        // setButacaParallaxProps({ xy: calc(width, x, height, y, 12.5) })
    }

    /**
     * Pasos
     */
    const [currentStep, setCurrentStep] = useState(0);
    const scrollDirection = useScroll();

    const sliderTransition = useTransition(currentSlide, p => p, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: {
            duration: 1000
        }
    })

    /**
     * Spring
     */
    const rightSpringInitialProps = { left: '100%', opacity: 0 };
    const leftSpringInitialProps = { left: 0, a: 1 };
    const butacaSpringInitialProps = { left: 240, top: 190, opacity: 1 };
    // const zeppelinSpringInitialProps = { left: -34, top: -56, opacity: 1 };
    // const maletasSpringInitialProps = { left: 250, top: 382, opacity: 1 };

    const [leftSpringProps, setLeftSpringProps] = useSpring(() => leftSpringInitialProps)
    const [rightSpringProps, setRightSpringProps] = useSpring(() => rightSpringInitialProps);
    const [butacaSpringProps, setButacaSpringProps] = useSpring(() => butacaSpringInitialProps);
    // const [zeppelinSpringProps, setZeppelinSpringProps] = useSpring(() => zeppelinSpringInitialProps);
    // const [maletasSpringProps, setMaletasSpringProps] = useSpring(() => maletasSpringInitialProps);

    const handleChangeStep = () => {
        let nextStep = currentStep + 1;

        if (nextStep < animations.length && nextStep >= 0) {
            animations[nextStep]();
            setCurrentStep(nextStep);
        }

    }

    useEffect(() => {

        if (scrollDirection === false) return;
        let nextStep = currentStep + (scrollDirection === 'down' ? 1 : -1);

        if (nextStep < 0)
            nextStep = 0;
        if (nextStep >= animations.length) return;

        if (nextStep < animations.length && nextStep >= 0) {
            animations[nextStep]();
        }

        setCurrentStep(nextStep);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scrollDirection]);

    const animations = [
        () => {
            setLeftSpringProps(leftSpringInitialProps);
            setRightSpringProps(rightSpringInitialProps);

            setUseParallax(true);
            handleReset();

            setButacaSpringProps(butacaSpringInitialProps);
            // setMaletasSpringProps(maletasSpringInitialProps);
            // setZeppelinSpringProps(zeppelinSpringInitialProps);
        },

        () => {
            setUseParallax(false);

            setLeftSpringProps({
                left: -450,
            })

            //Esto es el movimiento que se le daba a algunos componentes estáticos cuando cambiamos de escenario.
            // setGlobosIzdaProps({ xy: [94, 0] });
            // setAvionetaParallaxProps({ xy: [41, 0] });
            // setButacaParallaxProps({ xy: [64, 0] });
            // setZeppelinSpringProps({
            //     left: 345 + offSet,
            //     duration: 250
            // })

            // setMaletasSpringProps({
            //     opacity: 0,
            // })



            setTimeout(() => {

                setRightSpringProps({
                    left: '50%',
                    opacity: 1
                })

            }, 100)
            muestraSlide(0);

        },

        // () => {
        //     muestraSlide(1);
        // },
        //
        // () => {
        //     muestraSlide(2)
        // },
        //
        // () => {
        //
        //     muestraSlide(3)
        // },
        //
        // () => {
        //
        //     muestraSlide(4)
        // }
    ];

    const images = [


        {
            src: require("../img/salud/globos-izda.png"),
            style: { transform: globosIzda.xy.interpolate(transGlobos) },
            alt: 'Globos'
        },

        {
            src: require("../img/salud/zeppelin.png"),
            style: { transform: zeppelinParallaxProps.xy.interpolate(transZeppelin) },
            alt: 'zeppelin'
        },
        {
            src: require("../img/salud/avioneta.png"),
            style: { transform: avionetaParallaxProps.xy.interpolate(transAvioneta) },
            alt: 'Avioneta',
        },
        {
            src: require("../img/salud/coche.png"),
            style: { transform: cocheParallaxProps.xy.interpolate(transCoche) },
            alt: 'Coche'
        },
        {
            src: require("../img/salud/f.png"),
            style: { transform: fParallaxProps.xy.interpolate(transF) },
            alt: 'F'
        },
        {
            src: require("../img/salud/barco.png"),
            style: { transform: barcoParallaxProps.xy.interpolate(transBarco) },
            alt: 'Barco',
        },
        {
            src: require("../img/salud/globo-derecha.png"),
            style: { transform: globoDParallaxProps.xy.interpolate(transGloboD) },
            alt: 'Globo Derecha',
        },
        {
            src: require("../img/salud/butaca.png"),
            style: butacaSpringProps,//{ transform: butacaParallaxProps.xy.interpolate(transButaca) },
            alt: 'Butaca'
        },
        {
            src: require("../img/salud/maletas.png"),
            style: { transform: maletasParallaxProps.xy.interpolate(transMaletas) },
            alt: 'golfista'
        },
    ]

    return (
        <>
            <SubmenuPrestaciones />
            <div className="container ">
                <Loader
                    className={isLoaded ? 'hide' : 'spinner'}
                    text="Otra forma de entender la vida, otra forma de asegurar la salud."
                />
                <div
                    className="capas"
                    style={isLoaded ? {} : { display: 'none' }}
                >
                    {
                        error ? <Error text={'<p>Ha ocurrido un error inesperado. Por favor, vuelva a cargar la página dentro de unos minutos. </p><p>Si ve que el problema persiste, póngase en contacto con el servicio técnico.</p>'} /> :
                            (
                                <div>
                                    <animated.div
                                        className="left"
                                        style={{ ...leftSpringProps, width: '1024px', height: '550px' }}
                                        onMouseMove={({ clientX: x, clientY: y }) => useParallax && handleChange(1024, 550, x, y)}
                                        onMouseLeave={() => useParallax && handleReset()}
                                    >

                                        <div>
                                            <video style={{ width: '100%', height: '100%', position: 'absolute' }} autoPlay="autoplay" loop="loop" id="video_background" preload="auto" volume="50" muted playsInline disablePictureInPicture controlsList="nodownload">
                                                <source src="./salud.mp4" type="video/mp4" />
                                            </video>
                                        </div>

                                        {images.length > 0 && images.map((image, i) => {

                                            return (
                                                <animated.div key={i} className="se" style={image.style}>
                                                    <img src={image.src} alt={image.alt} onLoad={image.onLoad !== undefined ? image.onLoad : undefined} />
                                                </animated.div>
                                            )
                                        })}
                                    </animated.div>

                                    <animated.div className="right right-home" style={{ ...rightSpringProps, height: '550px', top: 30 }}>
                                        {loading ? (
                                            <Loader
                                                text="Cargando textos. Por favor, espere..."
                                            />
                                        ) : (
                                                <>
                                                    {sliderTransition.map(({ item, props, key }) => {
                                                        return <PolizaSliderItem
                                                            key={key}
                                                            currentStep={currentStep}
                                                            item={slides[item]}
                                                            style={props}
                                                        />
                                                    })}
                                                    {/*<Dots items={slides} currentStep={currentStep - 1} />*/}
                                                </>
                                            )}
                                    </animated.div>
                                    {slides !== null && currentStep < slides.length &&
                                        <Mano
                                            className="mano mano-right"
                                            onSetLoaded={() => setLoaded(true)}
                                            onChangeStep={handleChangeStep}
                                        />
                                        // <img src={mano} alt="mano" className="mano mano-right" onLoad={() => setLoaded(true)} onClick={() => handleChangeStep()} />
                                    }
                                </div>
                            )
                    }
                </div>

            </div>
        </>
    );
}

export default Salud;
