import React from 'react';
import { ErrorMessage } from "formik";
import PropTypes from 'prop-types';

/**
 *
 * @param field
 * @param form
 * @param label
 * @param type
 * @returns {*}
 * @constructor
 */

const TextComponent = ({ field, form, label, type, placeholder, onChange, error = false }) => {

    // const [value, setValue] = useState(field.value);
    // const [error, setError] = useState();
    //
    // const handleBlur = () => {
    //     form.setFieldValue(field.name, value);
    //     form.setFieldTouched(field.name, true);
    // }

    let className = '';

    if (error !== undefined && error)
        className = 'is-invalid';

    return (
        <div className="form-group">
            {label && <label>{label}</label> }
            <input
                {...field}
                placeholder={placeholder}
                type={type}
                onChange={onChange !== undefined ? onChange : field.onChange}
                className={'form-control ' + className}
                autoComplete="off"
            />

            <ErrorMessage name={field.name} className="invalid-feedback" component="div" />
        </div>
    )
}


TextComponent.propTypes = {
    type: PropTypes.string.isRequired
}

export default TextComponent;
