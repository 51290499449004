import React, {useEffect, useState} from 'react';
import Loader from "../components/Loader";
import Error from "../components/Error";
import MobileSlideItem from "../components/MobileSlideItem/MobileSlideItem";
import {useParams} from "react-router-dom";

const SaludMobile = props => {

    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState([]);
    const [mainData, setMainData] = useState({});
    const { IdContenido } = useParams();
    const [slideItemsRefs, setSlideItemsRefs] = useState([]);


    useEffect(() => {
        fetch("https://acunsafirst.es/json/first_salud_mobile.json")
            .then(r => r.json())
            .then(d => {
                setData(d.items)

                const refs = [];
                d.items.forEach(item => refs.push({
                    url: item.url,
                    ref: React.createRef()
                }))

                setSlideItemsRefs(refs)

                setMainData({
                    img: d.img || false,
                    content: d.content || false
                })

                setIsLoaded(true)

            }).catch(e => setError(true))
    }, []);

    //autoscroll to the active element when url changes
    useEffect(() => {

        if (slideItemsRefs.length === 0) return

        const currentItem = slideItemsRefs.find(i => i.url === IdContenido)

        setTimeout(() => currentItem?.ref.current.scrollIntoView(), 100)

    }, [IdContenido, slideItemsRefs])

    return <div>
        <Loader
            className={isLoaded ? 'hide' : 'spinner'}
        />

        {isLoaded && <>
            {error ?
                <Error text={'<p>Ha ocurrido un error inesperado. Por favor, vuelva a cargar la página dentro de unos minutos. </p><p>Si ve que el problema persiste, póngase en contacto con el servicio técnico.</p>'} />
                :
                <>
                    <div className="mobile_teaser">
                        {mainData.img && <img src={'https://acunsafirst.es/public-images/mobile/' + mainData.img} alt="First Salud" />}

                        {mainData.content && <div dangerouslySetInnerHTML={{__html: mainData.content}}></div>}
                    </div>

                    {data.map((item, index) =>
                        <MobileSlideItem componentRef={slideItemsRefs[index].ref} active={IdContenido === item.url} key={index} item={item} />
                    )}
                </>
            }
        </>}
    </div>;
}

export default SaludMobile;

