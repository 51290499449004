import React, { useEffect } from 'react';
import { animated, useSpring } from "react-spring";
import relojCentralSrc from '../../img/salud/100/3-reloj-central.png';

const offSet = 100;

const interp = i => r => `translate3d(0, ${7 * Math.sin(r + (i * 2 * Math.PI) / 1.6)}px, 0)`;

const RelojCentral = props => {

    const relojcContainerInitialProps = {
        top: offSet * 2,
        left: -58,
        opacity: 0
    }

    const relojcInitialProps = {
        to: async (next, cancel,) => {
            while (true) {
                await next({ radians: 2 * Math.PI })
            }
        },
        from: { radians: 0 },
        config: { duration: 4000 },
        reset: true,
    };

    const [relojcContainer, setRelojcContainer] = useSpring(() => relojcContainerInitialProps)
    const [relojc, setRelojc, stopRelojc] = useSpring(() => relojcInitialProps);

    useEffect(() => {
        if (!props.entrando) return;

        setRelojcContainer({
            top: 132,
            opacity: 1
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.entrando])

    useEffect(() => {
        if (!props.saliendo) return;

        setRelojcContainer(
            props.scroll === 'down' ?
                {
                    top: -offSet,
                    opacity: 0
                } : relojcContainerInitialProps
        );

        stopRelojc()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.saliendo])


    return <animated.div
        style={relojcContainer}
        className="se">
        <animated.div
            style={{ transform: relojc.radians.interpolate(interp(2)) }}
        >
            <img src={relojCentralSrc} alt="Reloj central" />
        </animated.div>
    </animated.div>
};

export default RelojCentral;
