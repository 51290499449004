import React from 'react';

import Aceitera from "../components/Elementos/Aceitera";
import Plato from "../components/Elementos/Plato";
import Zamburinia from "../components/Elementos/Zamburinia";

const Escenario5 = ({ enter, leave, scrollDirection }) => {
    return (
        <div className="escenario escenario5">
            <Plato entrando={enter} scroll={scrollDirection} saliendo={leave} />
            <Zamburinia entrando={enter} scroll={scrollDirection} saliendo={leave} />
            <Aceitera entrando={enter} scroll={scrollDirection} saliendo={leave} />
        </div>
    )
}

export default Escenario5;