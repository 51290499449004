import React from 'react';

import Tenis from "../components/Elementos/Tenis";
import Esgrima from "../components/Elementos/Esgrima";
import Aro2 from '../components/Elementos/Aro2';

const Escenario2 = ({ enter, leave, scrollDirection }) => {
    return (
        <div className="escenario escenario2">
            <Aro2 entrando={enter} scroll={scrollDirection} saliendo={leave} />
            <Tenis entrando={enter} scroll={scrollDirection} saliendo={leave} />
            <Esgrima entrando={enter} scroll={scrollDirection} saliendo={leave} />
        </div>
    )
}

export default Escenario2;