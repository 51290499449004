import React, { useEffect } from 'react';
import { animated, useSpring } from "react-spring";
import maletasSrc from '../../img/salud/100/4-maletas.png';

const offSet = 100;

const interp = i => r => `translate3d(0, ${7.5 * Math.sin(r + (i * 2 * Math.PI) / 1.6)}px, 0)`;

const RelojCentral = props => {

    const maletasContainerInitialProps = {
        top: offSet * 2,
        left: -165,
        opacity: 0
    }

    const maletasInitialProps = {
        to: async (next, cancel,) => {
            while (true) {
                await next({ radians: 2 * Math.PI })
            }
        },
        from: { radians: 0 },
        config: { duration: 4000 },
        reset: true,
    };

    const [maletasContainer, setMaletasContainer] = useSpring(() => maletasContainerInitialProps);
    const [maletas, setMaletas, stopMaletas] = useSpring(() => maletasInitialProps);

    useEffect(() => {
        if (!props.entrando) return;

        setMaletasContainer({
            top: 285,
            opacity: 1
        })
    }, [props.entrando])

    useEffect(() => {
        if (!props.saliendo) return;

        setMaletasContainer(
            props.scroll === 'down' ?
                {
                    top: -offSet,
                    opacity: 0
                } : maletasContainerInitialProps
        );

        stopMaletas()

    }, [props.saliendo])


    return <animated.div
        style={maletasContainer}
        className="se">
        <animated.div
            style={{ transform: maletas.radians.interpolate(interp(2)) }}
        >
            <img src={maletasSrc} alt="Maletas" />
        </animated.div>
    </animated.div>
};

export default RelojCentral;
