import React from 'react';

import Mundo from "../components/Elementos/Mundo";
import Coche from "../components/Elementos/Coche";
import Avioneta from "../components/Elementos/Avioneta";
import Carrusel from "../components/Elementos/Carrusel";
import Telescopio from "../components/Elementos/Telescopio";
import NubePeque from '../components/Elementos/NubePeque';
import NubeGrande from '../components/Elementos/NubeGrande';

const Escenario4 = ({ enter, leave, scrollDirection }) => {
    return (
        <div className="escenario escenario4">

            <NubePeque entrando={enter} scroll={scrollDirection} saliendo={leave} />
            <NubeGrande entrando={enter} scroll={scrollDirection} saliendo={leave} />
            <Mundo entrando={enter} scroll={scrollDirection} saliendo={leave} />
            <Coche entrando={enter} scroll={scrollDirection} saliendo={leave} />
            <Avioneta entrando={enter} scroll={scrollDirection} saliendo={leave} />
            <Carrusel entrando={enter} scroll={scrollDirection} saliendo={leave} />
            <Telescopio entrando={enter} scroll={scrollDirection} saliendo={leave} />
        </div>
    )
}

export default Escenario4;
