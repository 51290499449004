import React from 'react';
import {Link} from "react-router-dom";

import imageTitle from '../../img/100.png'

const MobileSlideItem = ({item, active, componentRef}) => <div className="mobile_slide_item" ref={componentRef}>

    <div className="mobile_slide_item_title">
        <Link to={`/poliza-acunsa-first-salud/${item.url}`}>
            <h2>
                <img src={imageTitle} alt={`100% ${item.title}`}/>
                {item.title}
            </h2>
        </Link>
    </div>

    {active && <div className="mobile_slide_item_content">
        {item.img && <img src={`https://acunsafirst.es/public-images/mobile/${item.img}`} alt={item.title} />}
        {item.content && <div className="mobile_slide_item_content" dangerouslySetInnerHTML={{__html: item.content}} /> }
    </div>}

</div>;

export default MobileSlideItem;
