import React, {useEffect, useState, createContext} from 'react';

import { isWindows, isMacOs, isFirefox, isSafari, isIE, isChrome, isEdge} from "react-device-detect";
import './App.scss';
import Menu from './components/Menu';
import Routes from './Routes';
import {BrowserRouter as Router} from "react-router-dom";
import { PageContext } from "./context/PageContext";



// import React from "react";






function App() {

    const [isDesktop, setIsDesktop] = useState(undefined)

    const handleResize = () => {
        setIsDesktop(window.innerWidth > 1023)
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize)
        handleResize()
        return () => window.removeEventListener("resize", handleResize)
    }, []);

    useEffect(() => {

        if (isDesktop) {
            document.body.classList.add('desktop');
            document.body.classList.remove('mobile');
        } else {
            document.body.classList.add('mobile');
            document.body.classList.remove('desktop');
        }

    }, [isDesktop]);

    //set browser and os clases in the body
    useEffect(() => {

        if (isWindows)
            document.body.classList.add('windows');
        if (isMacOs)
            document.body.classList.add('macos');

        if (isFirefox)
            document.body.classList.add('firefox');

        if (isSafari)
            document.body.classList.add('safari');

        if (isIE)
            document.body.classList.add('ie');

        if (isChrome)
            document.body.classList.add('chrome');

        if (isEdge)
            document.body.classList.add('edge');

    }, [])




    if (isDesktop === undefined) return null

    return (
        <div>
            <PageContext.Provider value={{ isDesktop }}>
            <Router>
                <header>
                    <Menu/>
                </header>
                <Routes isDesktop={isDesktop}/>
            </Router>
            </PageContext.Provider>
        </div>
    );
}

export default App;
