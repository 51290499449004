import React, { useEffect } from 'react';
import { animated, useSpring } from "react-spring";
import nubeGrandeSrc from '../../img/nube-grande.png';

const offSet = 100;

const interpNube = i => r => `translate3d(0, ${1 * Math.sin(r + (i * 2 * Math.PI) / 1.6)}px, 0)`;

const NubeGrande = props => {

    const nubeGrandeContainerInitialProps = {
        top: offSet * 2,
        left: -7,
        opacity: 0
    }

    const nubeGrandeInitialProps = {
        to: async (next, cancel,) => {
            while (true) {
                await next({ radians: 2 * Math.PI })
            }
        },
        from: { radians: 0 },
        config: { duration: 4000 },
        reset: true,
    };

    const [nubeGrandeContainer, setNubeGrandeContainer] = useSpring(() => nubeGrandeContainerInitialProps)
    const [nubeGrande, setNubeGrande, stopNubeGrande] = useSpring(() => nubeGrandeInitialProps);

    useEffect(() => {
        if (!props.entrando) return;

        setNubeGrandeContainer({
            top: -70,
            left: -55,
            opacity: 1,
        })
    }, [props.entrando])

    useEffect(() => {
        if (!props.saliendo) return;

        setNubeGrandeContainer(
            props.scroll === 'down' ?
                {
                    top: -offSet,
                    opacity: 0
                } : nubeGrandeContainerInitialProps
        );

        stopNubeGrande()

    }, [props.saliendo])


    return <animated.div
        style={nubeGrandeContainer}
        className="se"
    >
        <animated.div
            style={{ transform: nubeGrande.radians.interpolate(interpNube(2)) }}
        >
            <img src={nubeGrandeSrc} alt="Nube" />
        </animated.div>
    </animated.div>
};

export default NubeGrande;
