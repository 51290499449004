import React, {useEffect, useState} from 'react';
import {Link, NavLink, useLocation} from 'react-router-dom';

import { ReactComponent as SVGClose } from "./img/cancel.svg";
import s from './menu.module.scss'

import inicioVerVideoImg from '../../img/inicio-ver-video-mobile.png'
import ModalVideo from "react-modal-video";
// const Sidebar = ({ visible, onClose }) => <div id="sidebar-menu" className={`${s.sidebar} ${visible && s.sidebarOpenned}`}>
const Sidebar = ({ visible, onClose }) => {
    const location = useLocation();
    console.log(location.pathname)

    const [activeItem, setActiveItem] = useState('');
    const [videoOpenned, setVideoOpenned] = useState(false);

    useEffect(() => {

        ['salud', 'sports', 'arts'].forEach(index => {
            if (location.pathname.startsWith(`/${index}`) ||  location.pathname.startsWith(`/poliza-acunsa-first-${index}`)) {
                setTimeout(() => {
                    setActiveItem(index)
                }, 500)
            }
        })

    }, [location.pathname])

    return <div id="sidebar-menu" className={`${s.sidebar} ${visible && s.sidebarOpenned}`}>
    <span
        className={s.close}
        onClick={() => onClose()}

    ><SVGClose /></span>
        <ul>
            <li className={activeItem === 'salud' ? s.active : ''}><NavLink onClick={() => onClose()} activeClassName={s.activeLink} to="/salud"><span className={s.title}>FIRST Salud</span></NavLink>
                <ul>
                    <li><NavLink onClick={() => onClose()} className={s.link} activeClassName={s.activeLink} to={'/poliza-acunsa-first-salud/clinica-universidad-navarra'}>100% Clínica Universidad de Navarra</NavLink></li>
                    <li><NavLink onClick={() => onClose()} className={s.link} activeClassName={s.activeLink} to={'/poliza-acunsa-first-salud/medicina-preventiva'}>100% Medicina Preventiva</NavLink></li>
                    <li><NavLink onClick={() => onClose()} className={s.link} activeClassName={s.activeLink} to={'/poliza-acunsa-first-salud/reembolso-gastos'}>100% Reembolso de gastos</NavLink></li>
                    <li><NavLink onClick={() => onClose()} className={s.link} activeClassName={s.activeLink} to={'/poliza-acunsa-first-salud/garantias-exclusivas'}>100% Garantías Exclusivas</NavLink></li>
                    <li><NavLink onClick={() => onClose()} className={s.link} activeClassName={s.activeLink} to={'/poliza-acunsa-first-salud/cuadro-medico-nacional'}>100% Cuadro Médico Nacional</NavLink></li>
                </ul>
            </li>


            <li className={activeItem === 'sports' ? s.active : ''}><NavLink onClick={() => onClose()} activeClassName={s.activeLink} to="/sports"><span className={s.title}>FIRST Sports</span></NavLink>
                <ul>
                    <li><NavLink onClick={() => onClose()} className={s.link} activeClassName={s.activeLink} to={'/poliza-acunsa-first-sports/clinica-universidad-navarra'}>100% Clínica Universidad de Navarra</NavLink></li>
                    <li><NavLink onClick={() => onClose()} className={s.link} activeClassName={s.activeLink} to={'/poliza-acunsa-first-sports/medicina-preventiva'}>100% Medicina Preventiva</NavLink></li>
                    <li><NavLink onClick={() => onClose()} className={s.link} activeClassName={s.activeLink} to={'/poliza-acunsa-first-sports/reembolso-gastos'}>100% Reembolso de gastos</NavLink></li>
                    <li><NavLink onClick={() => onClose()} className={s.link} activeClassName={s.activeLink} to={'/poliza-acunsa-first-sports/garantias-exclusivas'}>100% Garantías Exclusivas</NavLink></li>
                    <li><NavLink onClick={() => onClose()} className={s.link} activeClassName={s.activeLink} to={'/poliza-acunsa-first-sports/cuadro-medico-nacional'}>100% Cuadro Médico Nacional</NavLink></li>
                </ul>
            </li>

            <li className={activeItem === 'arts' ? s.active : ''}><NavLink onClick={() => onClose()} activeClassName={s.activeLink} to="/arts"><span className={s.title}>FIRST Arts</span></NavLink>
                <ul>
                    <li><NavLink onClick={() => onClose()} className={s.link} activeClassName={s.activeLink} to={'/poliza-acunsa-first-arts/clinica-universidad-navarra'}>100% Clínica Universidad de Navarra</NavLink></li>
                    <li><NavLink onClick={() => onClose()} className={s.link} activeClassName={s.activeLink} to={'/poliza-acunsa-first-arts/medicina-preventiva'}>100% Medicina Preventiva</NavLink></li>
                    <li><NavLink onClick={() => onClose()} className={s.link} activeClassName={s.activeLink} to={'/poliza-acunsa-first-arts/reembolso-gastos'}>100% Reembolso de gastos</NavLink></li>
                    <li><NavLink onClick={() => onClose()} className={s.link} activeClassName={s.activeLink} to={'/poliza-acunsa-first-arts/garantias-exclusivas'}>100% Garantías Exclusivas</NavLink></li>
                    <li><NavLink onClick={() => onClose()} className={s.link} activeClassName={s.activeLink} to={'/poliza-acunsa-first-arts/cuadro-medico-nacional'}>100% Cuadro Médico Nacional</NavLink></li>
                </ul>
            </li>

            <li className={s.infolink}>
                <NavLink onClick={() => onClose()} to="/contacto">Solicitud de información</NavLink>
            </li>

            <li className={s.infolink}>
                <a href="https://cdn.acunsa.es/material/descargas/First-Coberturas.pdf" onClick={() => onClose()} target="_blank">Folleto Coberturas</a>
            </li>
        </ul>

        <div className={s.videoLink}>
            <img onClick={() => setVideoOpenned(true)} src={inicioVerVideoImg} alt="Ver vídeo"/>
        </div>


        <ModalVideo channel='youtube' autoplay isOpen={videoOpenned} videoId="uk4BfUxWreI" onClose={() => setVideoOpenned(false)} />

        <div className={s.bottomLinks}>
            <Link onClick={() => onClose()} to="/informacion">Aviso legal</Link>
        </div>
        <div className={s.bottomLinks}>
            <a onClick={() => onClose()} href="https://canaldenunciasgfm.es/canal_front.aspx?uidcanal=471608f0-a5e9-45c1-a450-1dcbfd8193a0&lang=es-ES" target="_blank">Canal de denuncias</a>
        </div>

    </div>
}

export default Sidebar;
