import React, { useEffect } from 'react';
import { animated, useSpring } from "react-spring";
import relojAltoSrc from '../../img/salud/100/1-reloj-alto.png';

const offSet = 100;

const interp = i => r => `translate3d(0, ${1 * Math.sin(r + (i * 2 * Math.PI) / 1.6)}px, 0)`;

const RelojAlto = props => {

    const relojaContainerInitialProps = {
        top: offSet * 2,
        left: -43,
        opacity: 0
    }

    const relojaInitialProps = {
        to: async (next, cancel,) => {
            while (true) {
                await next({ radians: 2 * Math.PI })
            }
        },
        from: { radians: 0 },
        config: { duration: 2500 },
        reset: true,
    };

    const [relojaContainer, setRelojaContainer] = useSpring(() => relojaContainerInitialProps)
    const [reloja, setReloja, stopReloja] = useSpring(() => relojaInitialProps);

    useEffect(() => {
        if (!props.entrando) return;

        setRelojaContainer({
            top: -28,
            opacity: 1
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.entrando])

    useEffect(() => {
        if (!props.saliendo) return;

        setRelojaContainer(
            props.scroll === 'down' ?
                {
                    top: -offSet,
                    opacity: 0
                } : relojaContainerInitialProps
        );
        stopReloja()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.saliendo])


    return <animated.div
        style={relojaContainer}
        className="se">
        <animated.div
            style={{ transform: reloja.radians.interpolate(interp(2)) }}
        >
            <img src={relojAltoSrc} alt="Reloj alto" />
        </animated.div>
    </animated.div>
};

export default RelojAlto;
