import { useEffect, useState } from 'react';

const useScroll = props => {

    const [scrollDirection, setScrollDirection] = useState(false);

    const delay = 1500;
    let delaying = false;
    const deltaOffset = 0;

    const we = e => {

        if (delaying) return;
        console.log(delay);

        if (e.deltaY > deltaOffset) {
            setScrollDirection('down');
            delaying = true;
        } else if (e.deltaY < -deltaOffset) {
            setScrollDirection('up');
            delaying = true
        }

        if (delaying) {

            setTimeout(() => {
                delaying = false;
                setScrollDirection(false);

            }, delay);
        }
    }

    useEffect(() => {

        window.addEventListener('wheel', we)

        return () => window.removeEventListener('wheel', we)
    }, []);

    return scrollDirection;
}

export default useScroll;
