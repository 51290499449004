import React, { useEffect } from 'react';
import { animated, useSpring } from "react-spring";
import violinSrc from '../../img/salud/100/4-violin-reloj.png';

const offSet = 100;

const interp = i => r => `translate3d(0, ${9 * Math.sin(r + (i * 2 * Math.PI) / 1.6)}px, 0)`;

const Butaca = props => {

    const violinContainerInitialProps = {
        top: offSet * 2,
        left: 120,
        opacity: 0
    }

    const violinInitialProps = {
        to: async (next, cancel,) => {
            while (true) {
                await next({ radians: 2 * Math.PI })
            }
        },
        from: { radians: 0 },
        config: { duration: 4000 },
        reset: true,
    };

    const [violinContainer, setViolinContainer] = useSpring(() => violinContainerInitialProps)
    const [violin, setViolin, stopViolin] = useSpring(() => violinInitialProps);

    useEffect(() => {
        if (!props.entrando) return;

        setViolinContainer({
            top: 345,
            opacity: 1,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.entrando])

    useEffect(() => {
        if (!props.saliendo) return;

        setViolinContainer(
            props.scroll === 'down' ?
                {
                    top: -offSet,
                    opacity: 0
                } : violinContainerInitialProps
        );

        stopViolin()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.saliendo])


    return <animated.div
        style={violinContainer}
        className="se">
        <animated.div
            style={{ transform: violin.radians.interpolate(interp(2)) }}
        >
            <img src={violinSrc} alt="Violín" />
        </animated.div>
    </animated.div>
};

export default Butaca;
