import React, { useEffect, useState } from "react";
import m from './menu.module.scss';
import { ReactComponent as Burger } from '../../img/svg/burger.svg';
import {
    Link, useLocation
} from "react-router-dom";
import srcAcunsa from '../../img/logo-acunsa.png';
import srcFirst from '../../img/logo-first.png';
import Sidebar from "./Sidebar";

const Menu = () => {

    const [isMenuOpened, setIsMenuOpened] = useState(false);
    const location = useLocation();

    useEffect(() => {

        const menu = document.getElementById('sidebar-menu');

        const clickOutsideMenu = e => {
            if (isMenuOpened && !menu.contains(e.target)) {
                setIsMenuOpened(false);
            }
        }
        window.addEventListener('click', clickOutsideMenu);

        return () => window.removeEventListener('click', clickOutsideMenu);
    })

    useEffect(() => {
        if (isMenuOpened)
            document.body.classList.add('menuOpened');
        else
            document.body.classList.remove('menuOpened');
    }, [isMenuOpened]);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const gclid = searchParams.get('gclid');

        if (gclid) {
            setCookie('gclid', gclid, 30);
            console.log('gclid guardado en la cookie:', gclid);
        }
    }, [location.search]);


    const setCookie = (name, value, days) => {
        const expires = new Date();
        expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000); // Configura la expiración en días
        document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
    };



    return <div className="container">

        <Sidebar onClose={() => setIsMenuOpened(false)} visible={isMenuOpened} />
        <div className={m.menu_wrapper}>

            <div className={m.col}>
                <Burger
                    className={m.burger}
                    onClick={() => setIsMenuOpened(!isMenuOpened)}
                />
                <a href="https://www.acunsa.es" rel="noopener noreferrer" className={m.acunsa} target="_blank">
                    <img src={srcAcunsa} alt="Acunsa" />
                </a>
            </div>

            <div className={`${m.col} ${m.logoWrapper}`}>
                <Link to="/" className={m.logo}>
                    <img src={srcFirst} alt="ACUNSA FIRST" />
                </Link>
            </div>

            <div className={m.col + ' ' + m.links}>
                <Link to="/contacto">Solicitud de información</Link>&nbsp;|&nbsp;
                {/* <a rel="noopener noreferrer" href="/informacion">Información</a>&nbsp;|&nbsp; */}
                <a href="http://clubfirst.acunsa.es/" target="blank">Club First</a>
            </div>



            <div className={m.mobile_links}>
                <a href="http://clubfirst.acunsa.es/" target="blank">Club First</a>
            </div>
        </div>
    </div>
}


export default Menu;
