import React from 'react';
import src from '../../img/nube-grande.png';

const NubeGrandeEstatica = props => {
    return (
        <img src={src} alt="Nube grande" />
    )

}

export default NubeGrandeEstatica;
