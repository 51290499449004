import React from 'react';

import Zepellin from "../components/Elementos/Zepellin";
import GloboCentral from "../components/Elementos/GloboCentral";
import GloboDerecha from "../components/Elementos/GloboDerecha";
import GloboIzquierda from "../components/Elementos/GloboIzquierda";
import NubePeque from '../components/Elementos/NubePeque';
import NubeGrande from '../components/Elementos/NubeGrande';

const Escenario1 = ({ enter, leave }) => {
    return (
        <div className="escenario escenario1">
            <NubePeque entrando={enter} saliendo={leave} />
            <NubeGrande entrando={enter} saliendo={leave} />
            <Zepellin entrando={enter} offSet saliendo={leave} />
            <GloboCentral entrando={enter} saliendo={leave} />
            <GloboDerecha entrando={enter} saliendo={leave} />
            <GloboIzquierda entrando={enter} saliendo={leave} />
        </div>
    )
}

export default Escenario1;