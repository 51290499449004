import React from 'react';

import Pelotari from "../components/Elementos/Pelotari";
import Ciclismo from "../components/Elementos/Ciclismo";

const Escenario5 = ({ enter, leave, scrollDirection }) => {
    return (
        <div className="escenario escenario5">
            <Pelotari entrando={enter} scroll={scrollDirection} saliendo={leave} />
            <Ciclismo entrando={enter} scroll={scrollDirection} saliendo={leave} />
        </div>
    )
}

export default Escenario5;