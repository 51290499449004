import React from 'react';
import './index.scss';

const Dots = ({ align, items, currentStep, onChange }) => {

    const handleAlign = () => {
        return align === undefined ? 'dots-bottom' : 'dots-' + align;
    }

    const handleSelected = index => {
        onChange && onChange(index);
    }

    return (
        <div className={'dots ' + handleAlign()} >
            {items.map((item, i) => {
                return (<span key={i} onClick={() => { handleSelected(i) }} className={currentStep === i ? 'item selected' : 'item'}><span className="fill"></span></span>)
            })}
        </div>
    );
}

export default Dots