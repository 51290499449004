import React from 'react';

import Letras from "../components/Elementos/Letras";
import Anteojos from "../components/Elementos/Anteojos";
import Butaca from "../components/Elementos/Butaca";
import Violin from "../components/Elementos/Violin";
import NubePeque from '../components/Elementos/NubePeque';
import NubeGrande from '../components/Elementos/NubeGrande';

const Escenario5 = ({ enter, leave, scrollDirection }) => {
    return (
        <div className="escenario escenario5">
            <NubePeque entrando={enter} scroll={scrollDirection} saliendo={leave} />
            <NubeGrande entrando={enter} scroll={scrollDirection} saliendo={leave} />
            <Letras entrando={enter} scroll={scrollDirection} saliendo={leave} />
            <Anteojos entrando={enter} scroll={scrollDirection} saliendo={leave} />
            <Butaca entrando={enter} scroll={scrollDirection} saliendo={leave} />
            <Violin entrando={enter} scroll={scrollDirection} saliendo={leave} />
        </div>
    )
}

export default Escenario5;