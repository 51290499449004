import React from 'react';
import { ErrorMessage, getIn } from "formik";
import PropTypes from 'prop-types';

const CheckboxComponent = ({ field, form, label, value, disabled = false }) => {

    return <div className="form-group">
        <div className="form-check">
            <label
                className={'form-check-label ' + ((form.touched[field.name] && form.errors[field.name]) || (getIn(form.errors, field.name) && getIn(form.touched, field.name)) ? 'is-invalid' : '')}
            >
                <input
                    type="checkbox"
                    {...field}
                    checked={field.value}
                    disabled={disabled}
                    className="form-check-input"
                />
                <span dangerouslySetInnerHTML={{ __html: label }}></span>
            </label>

            <ErrorMessage name={field.name} className="invalid-feedback" component="div" />
        </div>
    </div>
}

CheckboxComponent.propTypes = {
    label: PropTypes.string.isRequired,
}

export default CheckboxComponent;
