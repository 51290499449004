import React from 'react';
import Home from './pages/Home';
import Arts from './pages/Arts';
import ArtsClinica100 from './pages/ArtsClinica100';
import Salud from './pages/Salud';
import SaludClinica100 from './pages/SaludClinica100';
import Sports from './pages/Sports';
import SportsClinica100 from './pages/SportsClinica100';
import Informacion from './pages/Informacion';
import Contacto from './components/Contacto';

// import Video from './components/Poliza/Video';

import {
    Switch,
    Route,
} from "react-router-dom";
import SaludMobile from "./pages/SaludMobile";
import SportsMobile from "./pages/SportsMobile";
import ArtsMobile from "./pages/ArtsMobile";
import Descargas from "./pages/Descargas";


const Routes = ({isDesktop}) => <Switch>

    <Route exact path="/" component={Home} />
    <Route exact path="/descargas" component={Descargas} />

    <Route exact path="/salud" component={isDesktop ? Salud : SaludMobile} />
    <Route exact path="/poliza-acunsa-first-salud/:IdContenido" component={isDesktop ? SaludClinica100 : SaludMobile}/>

    <Route exact path="/sports" component={isDesktop ? Sports : SportsMobile} />
    <Route exact path="/poliza-acunsa-first-sports/:IdContenido" component={isDesktop ? SportsClinica100 : SportsMobile} />

    <Route exact path="/arts" component={isDesktop ? Arts : ArtsMobile} />
    <Route exact path="/poliza-acunsa-first-arts/:IdContenido" component={isDesktop ? ArtsClinica100 : ArtsMobile} />

    <Route exact path="/informacion" component={Informacion} />
    <Route exact path="/contacto" component={Contacto} />

</Switch>

export default Routes
