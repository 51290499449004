import React from 'react';
import { animated, useSpring } from "react-spring";
import src from '../../img/nube-mediana.png';

const NubeMedianaEstatica = props => {

    const elementInitialProps = {
        to: async (next, cancel,) => {
            while (true) {
                await next({ radians: 2 * Math.PI })
            }
        },
        from: { radians: 0 },
        config: { duration: 4000 },
        reset: true,
    };
    
    const [element, setElement] = useSpring(() => elementInitialProps);

    return (
        <animated.div
            style={props.interp && { transform: element.radians.interpolate(props.interp(3)) }}
        >
            <img src={src} alt="Nube Mediana" />
        </animated.div>

    )

}

export default NubeMedianaEstatica;
