import React, { useEffect } from 'react';
import { animated, useSpring } from "react-spring";
import zeppelinSrc from "../../img/salud/100/1-zeppelin.png";

const interpZeppelin = i => r => `translate3d(0, ${2 * Math.sin(r + (i * 2 * Math.PI) / 1.6)}px, 0)`;

const offSet = 100;

const Zepellin = props => {

    const zeppelinContainerInitialProps = {
        opacity: 0,
        left: -141,
        top: props.offSet * 2
    }

    const zeppelinInitialProps = {
        to: async (next, cancel,) => {
            while (true) {
                await next({ radians: 2 * Math.PI })
            }
        },
        from: { radians: 0 },
        config: { duration: 2500 },
        reset: true,
    };

    const [zeppelinContainer, setZeppelinContainer] = useSpring(() => zeppelinContainerInitialProps)
    const [zeppelin, setZeppelin, stopZeppelin] = useSpring(() => zeppelinInitialProps);

    useEffect(() => {
        if (!props.entrando) return;

        setZeppelinContainer({
            top: -42,
            opacity: 1
        });
    }, [props.entrando])

    useEffect(() => {
        if (!props.saliendo) return;

        setZeppelinContainer(
            props.scroll === 'down' ?
                {
                    top: -offSet,
                    opacity: 0
                } : zeppelinContainerInitialProps
        );

        stopZeppelin()

    }, [props.saliendo])


    return <animated.div
        style={zeppelinContainer}
        className="se"
    >
        <animated.div
            style={{ transform: zeppelin.radians.interpolate(interpZeppelin(2)) }}
        >
            <img src={zeppelinSrc} alt="Zeppelín" />
        </animated.div>
    </animated.div>
};

export default Zepellin;
