import React, { useEffect } from 'react';
import { animated, useSpring } from "react-spring";
import elementSrc from '../../img/arts/100/4-bailarina-opera.png';

const interp = i => r => `translate3d(0, ${3 * Math.sin(r + (i * 1 * Math.PI) / 1.6)}px, 0)`;

const offSet = 100;

const BailarinaOpera = props => {

    const containerInitialProps = {
        top: offSet * 2,
        left: 0,
        opacity: 0
    }

    const elementInitialProps = {
        to: async (next, cancel,) => {
            while (true) {
                await next({ radians: 2 * Math.PI })
            }
        },
        from: { radians: 0 },
        config: { duration: 4000 },
        reset: true,
    };

    const [container, setContainer] = useSpring(() => containerInitialProps)
    const [element, setElement, stopElement] = useSpring(() => elementInitialProps);

    useEffect(() => {
        if (!props.entrando) return;

        setContainer({
            top: 210,
            opacity: 1,
        })
    }, [props.entrando])

    useEffect(() => {
        if (!props.saliendo) return;

        setContainer(
            props.scroll === 'down' ?
                {
                    top: -offSet,
                    opacity: 0
                } : containerInitialProps
        );

        stopElement()

    }, [props.saliendo])


    return <animated.div
        style={container}
        className="se"
    >
        <animated.div
            style={{ transform: element.radians.interpolate(interp(6)) }}
        >
            <img src={elementSrc} alt="BailarinaOpera" />
        </animated.div>
    </animated.div>
};

export default BailarinaOpera;
