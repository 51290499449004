import React from 'react';
import './index.scss';

const Loader = ({ className, text = 'Otra forma de entender la vida, otra forma de asegurar la salud.' }) => {
    return (
        <div className={className}>
            <div className="spinner-text">{text}</div>
            <div className="bounce">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
        </div>
    )
}

export default Loader;