import React from 'react';


const Descargas = props => <div className="container">

    <div className="row content_descargas">

        <div className="content_descargas_mobile_image"></div>

        <div className="col">
            <p>First ARTS</p>
            <a href="#" className="btn-download">DESCARGAR FOLLETO</a>
        </div>

        <div className="col">
            <p>First SALUD</p>
            <a href="#" className="btn-download">DESCARGAR FOLLETO</a>
        </div>

        <div className="col">
            <p>First SPORTS</p>
            <a href="#" className="btn-download">DESCARGAR FOLLETO</a>
        </div>

    </div>

</div>;

export default Descargas;
