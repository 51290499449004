import React from 'react';
import s from './contacto.module.scss';
import mano from '../../img/mano-derecha.png';
import telefono from '../../img/telefono-icono.png';
const ContactoStep1 = ({ onNextStep }) => <div className={`container ${s.contact}`}>
    <div className={s.solicitarInformacion} onClick={onNextStep}>
        <img src={mano} alt="solicite informacion"/>
        <span>Déjenos sus datos</span>
    </div>

    <a href="tel:900121213" className={`${s.solicitarInformacion} ${s.solicitarInformacionTelefono}`}>
        <img src={telefono} alt="solicite informacion"/>
        <span>900 12 12 13</span>
    </a>

    <div className={s.contactDetails + ' ' +s.contactDetailsStep1}> </div>
</div>

export default ContactoStep1
