import React, { useEffect } from 'react';
import { animated, useSpring } from "react-spring";
import butacaSrc from '../../img/salud/100/3-butaca.png';

const offSet = 100;

const interp = i => r => `translate3d(0, ${6 * Math.sin(r + (i * 2 * Math.PI) / 1.6)}px, 0)`;

const Butaca = props => {

    const butacaContainerInitialProps = {
        top: offSet * 2,
        left: 0,
        opacity: 0
    }

    const butacaInitialProps = {
        to: async (next, cancel,) => {
            while (true) {
                await next({ radians: 2 * Math.PI })
            }
        },
        from: { radians: 0 },
        config: { duration: 4000 },
        reset: true,
    };

    const [butacaContainer, setButacaContainer] = useSpring(() => butacaContainerInitialProps)
    const [butaca, setButaca, stopButaca] = useSpring(() => butacaInitialProps);

    useEffect(() => {
        if (!props.entrando) return;

        setButacaContainer({
            top: 281,
            opacity: 1,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.entrando])

    useEffect(() => {
        if (!props.saliendo) return;

        setButacaContainer(
            props.scroll === 'down' ?
                {
                    top: -offSet,
                    opacity: 0
                } : butacaContainerInitialProps
        );

        stopButaca()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.saliendo])


    return <animated.div
        style={butacaContainer}
        className="se">
        <animated.div
            style={{ transform: butaca.radians.interpolate(interp(2)) }}
        >
            <img src={butacaSrc} alt="Butaca" />
        </animated.div>
    </animated.div>
};

export default Butaca;
