import React from 'react';
import fondoInformacion from '../img/fondo-informacion.png';

const Informacion = () => {

    React.useEffect(() => {
        window.document.body.classList.add('withScroll')
        return () => {
            window.document.body.classList.remove('withScroll')
        };
    }, []);


    return <div className="container hasScroll">
        <div className="informacion-page">
            <img className="acnf-img" src={fondoInformacion} alt='Acunsa first, información' />

            <h2>OBJETO</h2>

            <p>El presente aviso legal regula el uso y utilización del sitio web ACUNSA FIRST, del que es titular ASISTENCIA CLÍNICA UNIVERSITARIA DE NAVARRA S.A. DE SEGUROS Y REASEGUROS, (en adelante, ACUNSA).</p>
            <p>La navegación por el sitio web de ACUNSA, le atribuye la condición de USUARIO del mismo y conlleva su aceptación plena y sin reservas de todas y cada una de las condiciones publicadas en este aviso legal, advirtiendo de que dichas condiciones podrán ser modificadas sin notificación previa por parte de ACUNSA, en cuyo caso se procederá a su publicación y aviso con la máxima antelación posible.</p>
            <p>Por ello es recomendable leer atentamente su contenido en caso de desear acceder y hacer uso de la información y de los servicios ofrecidos desde este sitio web.</p>
            <p>El usuario además, se obliga a hacer un uso correcto del sitio web de conformidad con las leyes, la buena fe, el orden público, los usos del tráfico y el presente Aviso Legal, y responderá frente a ACUNSA o frente a terceros, de cualesquiera daños y perjuicios que pudieran causarse como consecuencia del incumplimiento de dicha obligación.</p>
            <p>Cualquier utilización distinta a la autorizada está expresamente prohibida, pudiendo ACUNSA denegar o retirar el acceso y su uso en cualquier momento.</p>

            <h2>IDENTIFICACIÓN</h2>

            <p>ACUNSA., en cumplimiento de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico, le informa de que:</p>
            <p>Su titular es: ASISTENCIA CLÍNICA UNIVERSITARIA DE NAVARRA S.A. DE SEGUROS Y REASEGUROS. (EN ADELANTE ACUNSA).</p>
            <p>Su denominación comercial es: ACUNSA<br />
                        Su NIF es: A- 50005586<br />
                        Teléfono: 948 19 46 00<br />
                        Fax: 948 17 76 58<br />
                        Dirección Postal: Avda. Pío XII, 57, 31008 Pamplona – Navarra.</p>

            <h3>1- COMUNICACIONES</h3>

            <p>Para comunicarse con nosotros, ponemos a su disposición diferentes medios de contacto que detallamos a continuación:</p>
            <p>Teléfono: 948 19 46 00<br />
                        Correo electrónico: dpd@acunsa.es<br />
                        Dirección postal: Avda. Pío XII, 57, 31008 Pamplona – Navarra.</p>
            <p>Todas las notificaciones y comunicaciones entre los usuarios y ACUNSA se considerarán eficaces, a todos los efectos, cuando se realicen a través de cualquier medio de los detallados anteriormente salvo el teléfono.</p>
            <p>Delegado de Protección de Datos: podrá ponerse en contacto con el Delegado de Protección de Datos (en adelante, DPO) a través del correo electrónico dpd@acunsa.es o en la dirección postal en Avda. Pío XII 57, 31008-Pamplona – Navarra, para cualquier duda o necesidad que tuviere en materia de protección de datos.</p>

            <h3>2- CONTENIDO</h3>

            <p>En ACUNSA, a través de este Sitio Web (https://acunsa.es/) facilita datos de interés acerca de la Empresa, así como información, noticias o servicios web relacionados con la actividad. Esta información es la vigente en el momento de su publicación 27 de Octubre 2020.</p>
            <p>En ACUNSA presta especial atención a que la información del sitio web sea exacta y precisa, procediendo a su actualización de forma periódica y corrigiendo los errores tan pronto como estos hayan sido detectados. Con todo, ACUNSA no puede garantizar que la información esté actualizada y la inexistencia de errores en todo momento.</p>
            <p>La información publicada en este sitio web tiene únicamente carácter informativo.</p>

            <h3>3- CONDICIONES DE ACCESO Y UTILIZACIÓN</h3>

            <p>El sitio web y sus servicios son de acceso libre y gratuito. No obstante, ACUNSA puede condicionar la utilización de algunos de los servicios ofrecidos en su web a la previa cumplimentación del correspondiente formulario.</p>
            <p>El usuario garantiza la autenticidad y actualidad de todos aquellos datos que comunique al ACUNSA y será el único responsable de las manifestaciones falsas o inexactas que realice.</p>
            <p>El usuario se compromete expresamente a hacer un uso adecuado de los contenidos y servicios del ACUNSA y a no emplearlos para, entre otros:</p>
            <p>• Difundir contenidos delictivos, violentos, pornográficos, racistas, xenófobos, ofensivos, de apología del terrorismo o, en general, contrarios a la ley o al orden público.</p>
            <p>• Introducir en la red virus informáticos o realizar actuaciones susceptibles de alterar, estropear, interrumpir o generar errores o daños en los documentos electrónicos, datos o sistemas físicos y lógicos de ACUNSA o de terceras personas; así como obstaculizar el acceso de otros usuarios al sitio web y a sus servicios mediante el consumo masivo de los recursos informáticos a través de los cuales ACUNSA presta sus servicios.</p>
            <p>• Intentar acceder a las cuentas de correo electrónico de otros usuarios o a áreas restringidas de los sistemas informáticos de ACUNSA o de terceros y, en su caso, extraer información.</p>
            <p>• Vulnerar los derechos de propiedad intelectual o industrial, así como violar la confidencialidad de la información de ACUNSA o de terceros.</p>
            <p>• Suplantar la identidad de cualquier otro usuario.</p>
            <p>• Reproducir, copiar, distribuir, poner a disposición de, o cualquier otra forma de comunicación pública, transformar o modificar los contenidos, a menos que se cuente con la autorización del titular de los correspondientes derechos o ello resulte legalmente permitido.</p>
            <p>• Recabar datos con finalidad publicitaria y de remitir publicidad de cualquier clase y comunicaciones con fines de venta u otras de naturaleza comercial sin que medie su previa solicitud o consentimiento.</p>

            <h2>PROPIEDAD INTELECTUAL</h2>

            <p>ACUNSA es titular, y tiene la correspondiente licencia, sobre los derechos de explotación de propiedad intelectual e industrial de la WEB. Todos los contenidos de la Web, incluyendo el diseño gráfico, código fuente, logos, textos, gráficos, ilustraciones, fotografías, y demás elementos que aparecen en el Web, salvo que se indique lo contrario, son titularidad exclusiva de ACUNSA En ningún caso se entenderá que el acceso y navegación del Usuario implica una renuncia, transmisión, licencia o cesión total ni parcial de dichos derechos por parte de ACUNSA sobre cualquier otro derecho relacionado con su Web y los servicios ofrecidos en la misma. En consecuencia, no está permitido suprimir, eludir o manipular el aviso de derechos de autor («copyright») y cualesquiera otros datos de identificación de los derechos del ACUNSA o de sus titulares incorporados a los contenidos, así como los dispositivos técnicos de protección, las huellas digitales o cualesquiera mecanismos de información y/o de identificación que pudieren incluirse en los contenidos. Asimismo, está prohibido modificar, copiar, reutilizar, explotar, reproducir, comunicar públicamente, hacer segundas o posteriores publicaciones, cargar archivos, enviar por correo, transmitir, usar, tratar o distribuir de cualquier forma la totalidad o parte de los contenidos incluidos en la página www. acunsa.es para propósitos públicos o comerciales, si no se cuenta con la autorización expresa y por escrito del ACUNSA.</p>
            <p>El Usuario reconoce, en consecuencia, que la reproducción, distribución, comercialización, transformación, y en general, cualquier otra forma de explotación, por cualquier procedimiento, de todo o parte de los contenidos de este Web constituye una infracción de los derechos de propiedad intelectual y/o industrial del titular del Web o del titular de los mismos.</p>
            <p>Todos los contenidos del sitio web, como textos, fotografías, gráficos, imágenes, iconos, tecnología, software, así como su diseño gráfico y códigos fuente, constituyen una obra cuya propiedad pertenece al ACUNSA , sin que puedan entenderse cedidos al usuario ninguno de los derechos de explotación sobre los mismos más allá de lo estrictamente necesario para el correcto uso de la web.</p>
            <p>En definitiva, los usuarios que accedan a este sitio web pueden visualizar los contenidos y efectuar, en su caso, copias privadas autorizadas siempre que los elementos reproducidos no sean cedidos posteriormente a terceros, ni se instalen a servidores conectados a redes, ni sean objeto de ningún tipo de explotación.</p>
            <p>Asimismo, todas las marcas, nombres comerciales o signos distintivos de cualquier clase que aparecen en el sitio web son propiedad de ACUNSA, sin que pueda entenderse que el uso o acceso al mismo atribuya al usuario derecho alguno sobre los mismos.</p>
            <p>La distribución, modificación, cesión o comunicación pública de los contenidos y cualquier otro acto que no haya sido expresamente autorizado por el titular de los derechos de explotación quedan prohibidos.</p>
            <p>El establecimiento de un hiperenlace no implica en ningún caso la existencia de relaciones entre ACUNSA y el propietario del sitio web en la que se establezca, ni la aceptación y aprobación por parte del ACUNSA de sus contenidos o servicios.</p>
            <p>ACUNSA no se responsabiliza del uso que cada usuario le dé a los materiales puestos a disposición en este sitio web ni de las actuaciones que realice en base a los mismos.</p>

            <h2>RECOGIDA DE DATOS DE MENORES</h2>

            <p>El ACUNSA no autoriza a los menores de 14 años a facilitar sus datos personales, ya sea mediante la cumplimentación de los formularios web habilitados para la solicitud de servicios, los formularios de contacto, o mediante el envío de correos electrónicos.</p>
            <h3>PLUGINS O COMPLEMENTOS DE REDES SOCIALES</h3>
            <p>El presente Sitio Web integra plugins o complementos de redes sociales tales como Facebook, Instagram, Twitter y You Tube, respecto a dichos plugins, el usuario del sitio web deberá tener en cuenta lo siguiente:</p>
            <p>Si está registrado como usuario en la red social y al visitar el Sitio Web tiene la sesión abierta en cualquiera de estas redes sociales, al hacer «click» en sus plugins, la red social recibirá la información que le indica que ha visitado el Sitio. Además proporcionará información sobre su perfil de usuario en esa red social (nombre, email, foto, etc.).</p>
            <p>Si no está registrado en la red social, o no tiene la sesión abierta mientras visita el Sitio Web puede ser que la red social reciba y almacene al menos su dirección IP.</p>
            <p>Si no desea que una red social recopile los datos personales aquí detallados, ni que los comparta con nosotros ni con terceros, consulte la política de privacidad de la red social correspondiente y cierre sesión en la misma antes de visitar el Sitio Web. También podrá eliminar las cookies existentes que haya instalado la red social en su ordenador usando las funciones de su navegador.</p>

            <h2>4- EXCLUSIÓN DE GARANTÍAS Y DE RESPONSABILIDAD EN EL ACCESO Y LA UTILIZACIÓN</h2>

            <p>El contenido del presente sitio web es de carácter general y tiene una finalidad meramente informativa, sin que se garantice plenamente el acceso a todos los contenidos, ni su exhaustividad, corrección, vigencia o actualidad, ni su idoneidad o utilidad para un objetivo específico.</p>

            <p>ACUNSA. excluye, hasta donde permite el ordenamiento jurídico, cualquier responsabilidad por los daños y perjuicios de toda naturaleza derivados de:</p>

            <p>• La imposibilidad de acceso al sitio web o la falta de veracidad, exactitud, exhaustividad y/o actualidad de los contenidos, así como la existencia de vicios y defectos de toda clase de los contenidos transmitidos, difundidos, almacenados, puestos a disposición, a los que se haya accedido a través del sitio web o de los servicios que se ofrecen.</p>
            <p>• La presencia de virus o de otros elementos en los contenidos que puedan producir alteraciones en los sistemas informáticos, documentos electrónicos o datos de los usuarios.</p>
            <p>El incumplimiento de las leyes, la buena fe, el orden público, los usos del tráfico y el presente aviso legal como consecuencia del uso incorrecto del sitio web.</p>
            <p>En particular, y a modo ejemplificativo, ACUNSA no se hace responsable de las actuaciones de terceros que vulneren derechos de propiedad intelectual e industrial, secretos empresariales, derechos al honor, a la intimidad personal y familiar y a la propia imagen, así como la normativa en materia de competencia desleal y publicidad ilícita.</p>

            <p>Asimismo, ACUNSA declina cualquier responsabilidad respecto a la información que se halle fuera de esta web y no sea gestionada directamente por nuestro webmaster. La función de los links que aparecen en esta web es exclusivamente la de informar al usuario sobre la existencia de otras fuentes susceptibles de ampliar los contenidos que ofrece este sitio web. ACUNSA no garantiza ni se responsabiliza del funcionamiento o accesibilidad de los sitios enlazados; ni sugiere, invita o recomienda la visita a los mismos, por lo que tampoco será responsable del resultado obtenido. ACUNSA no se responsabiliza del establecimiento de hipervínculos por parte de terceros.</p>

            <h2>5- PROCEDIMIENTO EN CASO DE REALIZACIÓN DE ACTIVIDADES DE CARÁCTER ILÍCITO</h2>

            <p>En el caso de que cualquier usuario o un tercero considere que existen hechos o circunstancias que revelen el carácter ilícito de la utilización de cualquier contenido y/o de la realización de cualquier actividad en las páginas web incluidas o accesibles a través del sitio web, deberá enviar una notificación a ACUNSA. identificándose debidamente y especificando las supuestas infracciones.</p>
            
            <h2>6- PUBLICACIONES</h2>

            <p>La información administrativa facilitada a través del sitio web no sustituye la publicidad legal de las leyes, normativas, planes, disposiciones generales y actos que tengan que ser publicados formalmente a los diarios oficiales de las administraciones públicas, que constituyen el único instrumento que da fe de su autenticidad y contenido. La información disponible en este sitio web debe entenderse como una guía sin propósito de validez legal.</p>
            
            <h2>7- POLÍTICA DE PRIVACIDAD</h2>

            <p>Cuando precisemos obtener información por su parte, siempre le solicitaremos que nos la proporcione voluntariamente de forma expresa. Los datos recabados a través de los formularios de recogida de datos del sitio web u otras vías, serán incorporados a un tratamiento de datos de carácter personal del cual es responsable ASISTENCIA CLÍNICA UNIVERSITARIA DE NAVARRA S.A. DE SEGUROS Y REASEGUROS. (EN ADELANTE ACUNSA). (en adelante: ACUNSA).</p>
            <p>Esta entidad tratará los datos de forma confidencial y exclusivamente con la finalidad de ofrecer los servicios solicitados, con todas las garantías legales y de seguridad que impone el RGPD UE 2016/679, la Legislación Española de Aplicación LOPD 3/2018 de 5 de Diciembre y la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico.</p>
            <p>ACUNSA no cederá ni compartirá los datos con terceros sin su consentimiento expreso.</p>

            <p>Asimismo, ACUNSA, suprimirá o rectificará los datos cuando resulten inexactos, incompletos o hayan dejado de ser necesarios o pertinentes para su finalidad, de conformidad con lo previsto en el RGPD UE 2016/679, de Protección de Datos de Carácter Personal.</p>

            <p>El usuario podrá revocar el consentimiento prestado y ejercer los derechos de acceso, rectificación, supresión, limitación, revocación, oposición y portabilidad de sus datos dirigiéndose a tal efecto al domicilio siguiente: ACUNSA (a la att. Del Departamento protección de datos o persona de contacto), en el correo electrónico dpd@acunsa.es o a la atención del Delegado de Protección de datos en el email dpo@gfmservicios.com, o en el formulario al efecto, en nuestra página web, en el enlace Ejercicio de derechos ArSoL, identificándose debidamente e indicando de forma expresa concreto derecho que se quiere ejercer.</p>

            <h3>MEDIDAS DE SEGURIDAD</h3>

            <p>Las medidas de seguridad adoptadas por ACUNSA son aquellas requeridas, de conformidad con lo establecido en el artículo 32 del RGPD. En este sentido, ACUNSA, teniendo en cuenta el estado de la técnica, los costes de aplicación y la naturaleza, el alcance, el contexto y los fines del tratamiento, así como los riesgos de probabilidad y gravedad variables para los derechos y las libertades de las personas físicas, tiene establecidas las medidas técnicas y organizativas apropiadas para garantizar el nivel de seguridad adecuado al riesgo existente.</p>
            <p>En todo caso, ACUNSA: tiene implementados los mecanismos suficientes para:</p>
            <p>Garantizar la confidencialidad, integridad, disponibilidad y resiliencia permanentes de los sistemas y servicios de tratamiento.</p>
            <p>• Restaurar la disponibilidad y el acceso a los datos personales de forma rápida, en caso de incidente físico o técnico.</p>
            <p>• Verificar, evaluar y valorar, de forma regular, la eficacia de las medidas técnicas y organizativas implantadas para garantizar la seguridad del tratamiento.</p>
            <p>• Seudonimizar y cifrar los datos personales, en su caso.</p>
            <p>• ACUNSA, adopta los niveles de seguridad correspondientes requeridos en el RGPD UE 2016/679 y LOPD GDD 3/2018 y demás normativa aplicable. No obstante, no puede garantizar la absoluta invulnerabilidad de los sistemas, por tanto, no asume ninguna responsabilidad por los daños y perjuicios derivados de alteraciones que terceros puedan causar en los sistemas informáticos, documentos electrónicos o ficheros del usuario.</p>
            
            <p>Si opta por abandonar nuestro sitio web a través de enlaces a sitios web no pertenecientes a nuestra entidad, ACUNSA, no se hará responsable de las políticas de privacidad de dichos sitios web ni de las cookies que éstos puedan almacenar en el ordenador del usuario.</p>
            <p>Nuestra política con respecto al correo electrónico se centra en remitir únicamente comunicaciones que usted haya solicitado recibir.</p>
            <p>Si prefiere no recibir estos mensajes por correo electrónico le ofreceremos a través de los mismos la posibilidad de ejercer su derecho de cancelación y renuncia a la recepción de estos mensajes, en conformidad con lo dispuesto en el Título III, artículo 22 de la Ley 34/2002, de Servicios para la Sociedad de la Información y de Comercio Electrónico.</p>
            <p>Para más información relativa a nuestra Política de Privacidad acceda a través de este enlace: Política de privacidad.</p>

            <h2>8- LEGISLACIÓN APLICABLE</h2>

            <p>Las condiciones presentes se regirán por la legislación Europea y en este caso dependerá de la Autoridad de control, AEPD, legislación española vigente.</p>
            <p>La lengua utilizada será el castellano.</p>



        </div>
    </div>
}

export default Informacion;
