import React, { useEffect, useState } from 'react';
import useScroll from "../hooks/useScroll";
import { useSpring, animated, useTransition } from "react-spring";
import PolizaSliderItem from "../components/Poliza/PolizaSliderItem";
import Dots from "../components/Dots";
import Loader from '../components/Loader';
import Error from '../components/Error';
import { useParams } from 'react-router-dom';
import SubmenuPrestaciones from "../components/SubmenuPrestaciones/arts";
/**
 * Nubes
 */
import Nube from '../components/Nube';
// import NubeGrande from '../components/NubeGrandeEstatica';
import NubeMediana from '../components/NubeMedianaEstatica';
import NubePeque from '../components/NubePequeEstatica';
import NubePeque2 from '../components/NubePequeEstatica';

import Escenario1 from './Arts100Escenario1';
import Escenario2 from './Arts100Escenario2';
import Escenario3 from './Arts100Escenario3';
import Escenario4 from './Arts100Escenario4';
import Escenario5 from './Arts100Escenario5';

const getData = (IdContenido) => {
    let url = '';
    switch (IdContenido) {
        case 'medicina-preventiva':
            url = 'https://acunsafirst.es/json/first_arts_medicina100.json';
            break;
        case 'reembolso-gastos':
            url = 'https://acunsafirst.es/json/first_arts_reembolso100.json';
            break;
        case 'garantias-exclusivas':
            url = 'https://acunsafirst.es/json/first_arts_garantia100.json';
            break;
        case 'cuadro-medico-nacional':
            url = 'https://acunsafirst.es/json/first_arts_cuadro100.json';
            break;
        default:
            url = 'https://acunsafirst.es/json/first_arts_clinica100.json';

    }
    return fetch(url).then(function (response) {
        return response.json();
    });
}

const SportsClinica100 = props => {

    const rightSpringInitialProps = { opacity: 1 };
    const [rightSpringProps, setRightSpringProps] = useSpring(() => rightSpringInitialProps);

    const { IdContenido } = useParams();

    const scrollDirection = useScroll()

    const [offSet, setOffSet] = useState(window.innerHeight / 2);

    const [currentSlide, setCurrentSlide] = useState(0);
    const [currentStep, setCurrentStep] = useState(0);
    const [loading, setLoading] = useState(true);
    const [slides, setSlides] = useState(null);
    const [error, setError] = useState(false);
    const [isLoaded, setLoaded] = useState(false);

    const [enterE1, setEnterE1] = useState(false);
    const [leaveE1, setLeaveE1] = useState(true);

    const [enterE2, setEnterE2] = useState(false);
    const [leaveE2, setLeaveE2] = useState(true);

    const [enterE3, setEnterE3] = useState(false);
    const [leaveE3, setLeaveE3] = useState(true);

    const [enterE4, setEnterE4] = useState(false);
    const [leaveE4, setLeaveE4] = useState(true);

    const [enterE5, setEnterE5] = useState(false);
    const [leaveE5, setLeaveE5] = useState(true);

    useEffect(() => {
        setLoaded(false);
        setCurrentSlide(0)
        setCurrentStep(0);
        animations[0]();
        getSlideData();
    }, [IdContenido]);

    const getSlideData = () => {
        getData(IdContenido)
            .then(d => {
                setSlides(d.slides)
            })
            .catch(e => {
                console.log('Ha habido un error en la petición o en los textos. En caso de ser los textos, por favor revíselos.')
                setError(true)
            })
            .finally(() => {
                setLoading(false)
                setLoaded(true)
            })
    }

    useEffect(() => {
        window.addEventListener('resize', handleOffSet)

        return () => window.removeEventListener('resize', handleOffSet);
    }, []);

    useEffect(() => {

        if (isLoaded === false && scrollDirection === false)
            animations[0]();

        if (scrollDirection === false) return;
        let nextStep = currentStep + (scrollDirection === 'down' ? 1 : -1);

        if (nextStep < 0) nextStep = 0;

        if (nextStep >= slides.length) return;

        if (nextStep === currentStep) return;

        if (nextStep < animations.length)
            animations[nextStep]();
        else if (nextStep < slides.length)
            muestraSlide(nextStep)


        setCurrentStep(nextStep);

    }, [scrollDirection])

    const handleOffSet = e => {
        setOffSet(e.target.innerHeight / 2)
    }

    const muestraSlide = index => {
        setCurrentSlide(index);
    }

    const handleChangeStep = () => {
        let nextStep = currentStep + 1;

        if (nextStep < animations.length && nextStep >= 0) {
            animations[nextStep]();
            setCurrentStep(nextStep);
        } else if (nextStep < slides.length) {
            muestraSlide(nextStep)
            setCurrentStep(nextStep);
        }

    }

    const sliderTransition = useTransition(currentSlide, p => p, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: {
            duration: 1000
        }
    })

    const handleChange = index => {
        if (index < animations.length && index >= 0) {
            animations[index]();
            setCurrentStep(index);
            muestraSlide(index);
        } else if (index < slides.length) {
            animations[animations.length - 1]();
            muestraSlide(index)
            setCurrentStep(index);
        }

    }

    const hideClouds = () => {
        setNubePequeContainer({
            opacity: 0,
        })

        setNubePeque2Container({
            opacity: 0,
        })

        setNubeMedianaContainer({
            opacity: 0,
        })

        setNubeGrandeContainer({
            opacity: 0,
        })

        setNubeContainer({
            opacity: 0,
        })
    }

    const showE1 = () => {
        hideClouds();

        setEnterE1(true);
        setLeaveE1(false);

        setEnterE2(false);
        setLeaveE2(true);
        setEnterE3(false);
        setLeaveE3(true);
        setEnterE4(false);
        setLeaveE4(true);
        setEnterE5(false);
        setLeaveE5(true);

        setNubeMedianaContainer({
            opacity: 1,
            top: 200
        })

        setNubePequeContainer({
            opacity: 1,
            top: -135,
            left: -120
        })

        setNubePeque2Container({
            opacity: 1,
            top: -180,
            left: 100
        })
    }

    const showE2 = () => {
        hideClouds();

        setEnterE1(false);
        setLeaveE1(true);

        setEnterE2(true);
        setLeaveE2(false);

        setEnterE3(false);
        setLeaveE3(true);
        setEnterE4(false);
        setLeaveE4(true);
        setEnterE5(false);
        setLeaveE5(true);

        setNubeMedianaContainer({
            opacity: 1,
            top: -150,
            left: -100
        })

        setNubePequeContainer({
            opacity: 1,
            top: 20,
            left: -150
        })

        setNubeContainer({
            opacity: 1,
            top: 270,
            left: 20
        })
    }

    const showE3 = () => {

        hideClouds();

        setEnterE1(false);
        setLeaveE1(true);
        setEnterE2(false);
        setLeaveE2(true);

        setEnterE3(true);
        setLeaveE3(false);

        setEnterE4(false);
        setLeaveE4(true);
        setEnterE5(false);
        setLeaveE5(true);

        setNubeMedianaContainer({
            opacity: 1,
            top: 205,
            left: 10
        })

        setNubePeque2Container({
            opacity: 1,
            top: -115,
            left: -50
        })
    }

    const showE4 = () => {
        hideClouds();

        setEnterE1(false);
        setLeaveE1(true);
        setEnterE2(false);
        setLeaveE2(true);
        setEnterE3(false);
        setLeaveE3(true);

        setEnterE4(true);
        setLeaveE4(false);

        setEnterE5(false);
        setLeaveE5(true);

        setNubeGrandeContainer({
            opacity: 1,
            top: -120,
            left: -15
        })

        setNubePequeContainer({
            opacity: 1,
            top: 20,
            left: 125
        })

        setNubePeque2Container({
            opacity: 1,
            top: 0,
            left: -100
        })
    }

    const showE5 = () => {

        hideClouds();

        setEnterE1(false);
        setLeaveE1(true);
        setEnterE2(false);
        setLeaveE2(true);
        setEnterE3(false);
        setLeaveE3(true);
        setEnterE4(false);
        setLeaveE4(true);

        setEnterE5(true);
        setLeaveE5(false);

        setNubeMedianaContainer({
            opacity: 1,
            top: 175,
            left: 0
        })

        setNubePequeContainer({
            opacity: 1,
            top: -140,
            left: 15
        })
    }

    const animations = [
        () => {
            switch (IdContenido) {
                case 'medicina-preventiva':
                    showE2();
                    break;
                case 'reembolso-gastos':
                    showE3();
                    break;
                case 'garantias-exclusivas':
                    showE4();
                    break;
                case 'cuadro-medico-nacional':
                    showE5();
                    break;
                default:
                    showE1();
            }
            muestraSlide(0);
        },

        () => {
            switch (IdContenido) {
                case 'medicina-preventiva':
                    showE3();
                    break;
                case 'reembolso-gastos':
                    showE4();
                    break;
                case 'garantias-exclusivas':
                    showE5();
                    break;
                case 'cuadro-medico-nacional':
                    showE1();
                    break;
                default:
                    showE2();
            }
            muestraSlide(1);
        },

        () => {
            switch (IdContenido) {
                case 'medicina-preventiva':
                    showE4();
                    break;
                case 'reembolso-gastos':
                    showE5();
                    break;
                case 'garantias-exclusivas':
                    showE1();
                    break;
                case 'cuadro-medico-nacional':
                    showE2();
                    break;
                default:
                    showE3();
            }
            muestraSlide(2);

        },

        () => {
            switch (IdContenido) {
                case 'medicina-preventiva':
                    showE5();
                    break;
                case 'reembolso-gastos':
                    showE1();
                    break;
                case 'garantias-exclusivas':
                    showE2();
                    break;
                case 'cuadro-medico-nacional':
                    showE3();
                    break;
                default:
                    showE4();
            }
            muestraSlide(3);

        },

        () => {
            switch (IdContenido) {
                case 'medicina-preventiva':
                    showE1();
                    break;
                case 'reembolso-gastos':
                    showE2();
                    break;
                case 'garantias-exclusivas':
                    showE3();
                    break;
                case 'cuadro-medico-nacional':
                    showE4();
                    break;
                default:
                    showE5();
            }
            muestraSlide(4);

        }
    ];

    const nubeGrandeContainerInitialProps = {
        opacity: 0,
        top: '0%'
    }
    const [nubeGrandeContainer, setNubeGrandeContainer] = useSpring(() => nubeGrandeContainerInitialProps)
    const interpGrande = i => r => `translate3d(0, ${3 * Math.sin(r + (i * 1 * Math.PI) / 1.8)}px, 0)`;

    const nubeMedianaContainerInitialProps = {
        opacity: 0
    }
    const [nubeMedianaContainer, setNubeMedianaContainer] = useSpring(() => nubeMedianaContainerInitialProps)
    const interpMediana = i => r => `translate3d(0, ${3 * Math.sin(r + (i * 1 * Math.PI) / 1.8)}px, 0)`;

    const nubePequeContainerInitialProps = {
        from: {
            opacity: 0,
            top: '0%'
        },
        to: {
            opacity: 1,
        },

    }
    const [nubePequeContainer, setNubePequeContainer] = useSpring(() => nubePequeContainerInitialProps)
    const interpPeque = i => r => `translate3d(0, ${3 * Math.sin(r + (i * 2 * Math.PI) / 1.8)}px, 0)`;

    const nubePeque2ContainerInitialProps = {
        from: {
            opacity: 0,
            top: '0%'
        },
        to: {
            opacity: 1,
            top: offSet - 227
        },

    }
    const [nubePeque2Container, setNubePeque2Container] = useSpring(() => nubePeque2ContainerInitialProps)
    const interpPeque2 = i => r => `translate3d(0, ${5 * Math.sin(r + (i * 1 * Math.PI) / 1.8)}px, 0)`;

    const nubeContainerInitialProps = {
        from: {
            opacity: 0,
            top: '50%'
        },
        to: {
            opacity: 1,
            top: offSet - 227
        },

    }
    const [nubeContainer, setNubeContainer] = useSpring(() => nubeContainerInitialProps)

    return (
        <div className={`container container-salud onehundred-arts onehundred-${IdContenido}`}>

            <SubmenuPrestaciones />
            <Loader
                className={isLoaded ? 'hide' : 'spinner'}
            />
            <div
                className="capas"
                style={isLoaded ? {} : { display: 'none' }}
            >
                {
                    error ? <Error text={'<p>Ha ocurrido un error inesperado. Por favor, vuelva a cargar la página dentro de unos minutos. </p><p>Si ve que el problema persiste, póngase en contacto con el servicio técnico.</p>'} /> :
                        (
                            <div>
                                <animated.div className="left" >

                                    <animated.div
                                        className="se"
                                        style={nubeMedianaContainer}
                                    >
                                        <NubeMediana interp={interpMediana} />
                                    </animated.div>

                                    <animated.div
                                        className="se"
                                        style={nubePequeContainer}
                                    >
                                        <NubePeque interp={interpPeque} />
                                    </animated.div>

                                    <animated.div
                                        className="se"
                                        style={nubeContainer}
                                    >
                                        <Nube style={{ width: 512, height: 450, borderSizing: 'border-box' }} />
                                    </animated.div>

                                    <animated.div
                                        className="se"
                                        style={nubePeque2Container}
                                    >
                                        <NubePeque2 interp={interpPeque2} />
                                    </animated.div>

                                    <Escenario1 enter={enterE1} leave={leaveE1} />
                                    <Escenario2 enter={enterE2} leave={leaveE2} scrollDirection={scrollDirection} />
                                    <Escenario3 enter={enterE3} leave={leaveE3} scrollDirection={scrollDirection} />
                                    <Escenario4 enter={enterE4} leave={leaveE4} scrollDirection={scrollDirection} />
                                    <Escenario5 enter={enterE5} leave={leaveE5} scrollDirection={scrollDirection} />

                                </animated.div>

                                <animated.div className="right right-hundred" style={{ ...rightSpringProps }}>
                                    {loading ? (
                                        <Loader
                                            text="Cargando textos. Por favor, espere..."
                                        />
                                    ) : (
                                            <>
                                                {sliderTransition.map(({ item, props, key }) => {
                                                    return <PolizaSliderItem
                                                        key={key}
                                                        currentStep={currentStep}
                                                        item={slides[item]}
                                                        style={props}
                                                        manoVisible={slides !== null && currentStep < (slides.length - 1)}
                                                        onManoSetLoaded={() => setLoaded(true)}
                                                        onManoChangeStep={handleChangeStep}
                                                    />
                                                })}
                                                <Dots align={"right"} items={slides} currentStep={currentStep} onChange={e => handleChange(e)} />

                                            </>
                                        )}
                                </animated.div>
                            </div>
                        )
                }
            </div>
        </div >
    );
}

export default SportsClinica100;
