import React from "react";
import smp from './submenu-prestaciones.module.scss';
import {
    Link
} from "react-router-dom";
import src100 from '../../img/100.png';

const SubmenuPrestaciones = () => {

    const prestaciones = [
        <Link className={smp.title} to="/poliza-acunsa-first-arts/clinica-universidad-navarra">Clínica Universidad<br />de Navarra</Link>,
        <Link className={smp.title} to="/poliza-acunsa-first-arts/medicina-preventiva">Medicina<br />Preventiva</Link>,
        <Link className={smp.title} to="/poliza-acunsa-first-arts/reembolso-gastos">Reembolso<br />de gastos</Link>,
        <Link className={smp.title} to="/poliza-acunsa-first-arts/garantias-exclusivas">Garantías<br />Exclusivas</Link>,
        <Link className={smp.title} to="/poliza-acunsa-first-arts/cuadro-medico-nacional">Cuadro Médico<br />Nacional</Link>
    ]

    return (
        <div className="container submenu_options">
            <div className={smp.submenu_wrapper}>
                {prestaciones.map((i, k) =>
                    <div key={k}>
                        <div className={smp.item}>
                            <div className={smp.percent}><img src={src100} alt="Acunsa First 100%" /></div>
                            {i}
                        </div>
                        {k < prestaciones.length - 1 &&
                            <div className={smp.separator}></div>
                        }
                    </div>
                )}
            </div>
        </div>
    )
}


export default SubmenuPrestaciones;
