import React from 'react';
import src from "../../img/mano.png";

const Mano = (props) => {

    const handleClick = () => {
        props.onChangeStep && props.onChangeStep()
    }

    return (
        <img src={src} alt="mano" className={props.className} onLoad={() => props.onSetLoaded && props.onSetLoaded()} onClick={() => handleClick()} />
    )
}

export default Mano;