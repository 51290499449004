import React, {createRef, useContext} from 'react';
import imageSrc from '../img/home/salud.png';

import {
    Link,
} from "react-router-dom";
import {PageContext} from "../context/PageContext";

const HomeSalud = ({ onSetLoaded }) => {

    const saludV = createRef();
    const {isDesktop} = useContext(PageContext)

    return (
        <div
            className="home-item home-salud"
            onMouseEnter={() => isDesktop && saludV.current.play()}
            onMouseLeave={() => isDesktop && saludV.current.pause()}
        >

            <Link to={'/salud'}>
                {isDesktop ?
                    <video
                        ref={saludV}
                        muted="muted"
                        className="homeVideo"
                        loop="loop"
                        preload="auto"
                        playsInline
                        disablePictureInPicture
                        controlsList="nodownload"
                        onLoadedData={() => onSetLoaded()}
                    >
                        <source src="./home-salud.mp4" type="video/mp4"/>
                    </video>
                    :
                    <img src={process.env.PUBLIC_URL + '/public-images/mobile/inicio_salud.jpg'} alt="Salud"/>
                }
                <div className="sublink">
                    <div className="header">
                        <img src={imageSrc} alt="Salud" />
                        <p className="text">Un seguro de salud<br />exclusivo con<br/>coberturas únicas</p>
                    </div>
                    <div className="title">
                        FIRST SALUD
                    </div>
                </div>
            </Link>
        </div>
    )

}

export default HomeSalud;
