import React from 'react'
import { Canvas } from 'react-three-fiber'

export default ({ children, style }) => {
  return (
    <div className="nubeCanvas">
      <Canvas
        camera={{ position: [0, 1, 3] }}
        style={style}
      >
        {children}
      </Canvas>
    </div>
  )
}
