import React from 'react';

import Remo from "../components/Elementos/Remo";
import Vela from "../components/Elementos/Vela";

const Escenario4 = ({ enter, leave, scrollDirection }) => {
    return (
        <div className="escenario escenario4">
            <Remo entrando={enter} scroll={scrollDirection} saliendo={leave} />
            <Vela entrando={enter} scroll={scrollDirection} saliendo={leave} />
        </div>
    )
}

export default Escenario4;