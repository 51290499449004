import React from 'react';

import Baloncesto from "../components/Elementos/Baloncesto";
import Futbol from "../components/Elementos/Futbol";
import Aro1 from '../components/Elementos/Aro1';

const Escenario1 = ({ enter, leave }) => {
    return (
        <div className="escenario escenario">
            <Aro1 entrando={enter} offSet saliendo={leave} />
            <Baloncesto entrando={enter} offSet saliendo={leave} />
            <Futbol entrando={enter} saliendo={leave} />
        </div>
    )
}

export default Escenario1;