import React from 'react';

import Torero from "../components/Elementos/Torero";
import Patinadora from "../components/Elementos/Patinadora";
import Bailaora from "../components/Elementos/Bailaora";

const Escenario3 = ({ enter, leave, scrollDirection }) => {
    return (
        <div className="escenario escenario2" >
            <div className="escenario escenario3">
                <Torero entrando={enter} scroll={scrollDirection} saliendo={leave} />
                <Bailaora entrando={enter} scroll={scrollDirection} saliendo={leave} />
                <Patinadora entrando={enter} scroll={scrollDirection} saliendo={leave} />
            </div>

        </div>
    )
}

export default Escenario3;