import React, { useEffect } from 'react';
import { animated, useSpring } from "react-spring";
import mundoSrc from '../../img/salud/100/1-mundo.png';

const offSet = 100;

const interp = i => r => `translate3d(0, ${1 * Math.sin(r + (i * 2 * Math.PI) / 1.6)}px, 0)`;

const Mundo = props => {

    const mundoContainerInitialProps = {
        top: offSet * 2,
        left: -64,
        opacity: 0
    }

    const mundoInitialProps = {
        to: async (next, cancel,) => {
            while (true) {
                await next({ radians: 2 * Math.PI })
            }
        },
        from: { radians: 0 },
        config: { duration: 2500 },
        reset: true,
    };

    const [mundoContainer, setMundoContainer] = useSpring(() => mundoContainerInitialProps)
    const [mundo, setMundo, stopMundo] = useSpring(() => mundoInitialProps);

    useEffect(() => {
        if (!props.entrando) return;

        setMundoContainer({
            top: -45,
            opacity: 1
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.entrando])

    useEffect(() => {
        if (!props.saliendo) return;

        setMundoContainer(
            props.scroll === 'down' ?
                {
                    top: -offSet,
                    opacity: 0
                } : mundoContainerInitialProps
        );

        stopMundo()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.saliendo])


    return <animated.div
        style={mundoContainer}
        className="se">
        <animated.div
            style={{ transform: mundo.radians.interpolate(interp(2)) }}
        >
            <img src={mundoSrc} alt="Mundo" />
        </animated.div>
    </animated.div>
};

export default Mundo;
