import React, {createRef, useContext} from 'react';
import imageSrc from '../img/home/sports.png';

import {
    Link,
} from "react-router-dom";
import {PageContext} from "../context/PageContext";

const HomeSports = ({ onSetLoaded }) => {

    const sportsV = createRef();

    const {isDesktop} = useContext(PageContext)

    return (
        <div
            className="home-item home-sports"
            onMouseEnter={() => isDesktop && sportsV.current.play()}
            onMouseLeave={() => isDesktop && sportsV.current.pause()}
        >
            <Link to={'/sports'}>
                {isDesktop ?
                    <video
                        ref={sportsV}
                        muted="muted"
                        className="homeVideo"
                        loop="loop"
                        preload="auto"
                        playsInline
                        disablePictureInPicture
                        controlsList="nodownload"
                        onLoadedData={() => onSetLoaded()}
                    >
                        <source src="./home-sports.mp4" type="video/mp4"/>
                    </video>
                    :
                    <img src={process.env.PUBLIC_URL + '/public-images/mobile/inicio_sports.jpg'} alt="Sports"/>
                }
                <div className="sublink">
                    <div className="header">
                        <img src={imageSrc} alt="Sports" />
                        <p className="text">Un seguro de salud<br />para deportistas<br />de élite
                        </p>
                    </div>
                    <div className="title">
                        FIRST SPORTS
                    </div>
                </div>
            </Link>
        </div>
    )

}

export default HomeSports;
