import React from 'react';

import RelojAlto from "../components/Elementos/RelojAlto";
import Brujula from "../components/Elementos/Brujula";
import RelojCentral from "../components/Elementos/RelojCentral";
import Maletas from "../components/Elementos/Maletas";
import NubePeque from '../components/Elementos/NubePeque';
import NubeGrande from '../components/Elementos/NubeGrande';

const Escenario3 = ({ enter, leave, scrollDirection }) => {
    return (
        <div className="escenario escenario3">
            <NubePeque entrando={enter} scroll={scrollDirection} saliendo={leave} />
            <NubeGrande entrando={enter} scroll={scrollDirection} saliendo={leave} />
            <RelojAlto entrando={enter} scroll={scrollDirection} saliendo={leave} />
            <Brujula entrando={enter} scroll={scrollDirection} saliendo={leave} />
            <RelojCentral entrando={enter} scroll={scrollDirection} saliendo={leave} />
            <Maletas entrando={enter} scroll={scrollDirection} saliendo={leave} />
        </div>
    )
}

export default Escenario3;